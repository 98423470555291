.badge {
  position: absolute;
  top: -4px;
  left: 21px;
  color: white;
  height: 16px;
  min-width: 14px;
  background-color: #f23c73;
  border-radius: 4px;
  padding: 0 2px;

  text-align: center;

  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}
