
.addNoteBackButton {
  font-weight: 500;
  color: var(--color-gray-700);
  margin-bottom: 1rem;
  margin-top: 1rem;
  margin-left: 16px;
}

.noteAreaTitle {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 23.52px;
  color: #101828;
  margin-top: 10px;
}

.noteArea {
  position: relative;
  border: 1px solid var(--color-gray-50);
  border-radius: 4px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
}

.note {
  border: none;
  padding: 8px 16px;
  height: 150px;
  width: 100%;
  text-align: start;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  resize: none;
  display: inline-block;
}

.note[data-status='loading'] {
  display: flex;
  align-items: center;
  justify-content: center;
}

textarea:focus {
  outline: 0;
}

.cancelButton {
  background: var(--color-white);
  border: 1px solid var(--color-gray-50);
  border-radius: 2px;
  padding: 8px 16px;
  width: 170px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cancelButton:hover,
.saveButton:hover {
  cursor: pointer;
  filter: brightness(90%);
}

.saveButton {
  background-color: var(--color-purple-600);
  margin-left: 10px;
  border: 1px solid var(--color-purple-600);
  border-radius: 2px;
  padding: 8px 16px;
  width: 170px;
  height: 32px;
  color: var(--color-white);
  display: flex;
  justify-content: center;
  align-items: center;
}

.saveButton[data-status='loading'] {
  opacity: 0.5;
}

.addNoteButtons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 10px 16px;
}

.spinner {
  width: 40px;
  height: 40px;
}
