.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
}

.tags[aria-busy='true'] {
  visibility: hidden;
}
.tags[aria-busy='false'] {
  visibility: visible;
}

.pill {
  margin: 0;
}

.tooltip {
  display: flex;
  align-items: center;
  justify-content: center;
}
.column {
  display: flex;
  flex-direction: column;
}
