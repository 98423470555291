.main {
  z-index: 9999;
}

.card {
  border-radius: 6px;
  border: 1px solid var(--color-gray-75);
  background: #fff;
  box-shadow: var(--shadow-xl);
  font-size: 12px;
  overflow: hidden;
}

/* mobile */
@media (max-width: 40rem) {
  .main {
    inset: 0px !important;
    transform: none !important;
  }
	.card {
		height: 100%;
    border-radius: 0;
	}
}

/* desktop */
@media (min-width: 40rem) {
  .main {
		width: fit-content;
  }
}
