.card {
  display: flex;
  background: var(--color-white);
  border: 0.549518px solid #EFEFEF;
  box-sizing: border-box;
  box-shadow: 0px 2.19807px 4.39614px rgba(0, 0, 0, 0.06);
  border-radius: 2.19807px;
  margin: 16px;
  flex-direction: column;
}

.topSection{
  padding: 10px 24px 10px 18px;
  border-bottom: 1px solid #EFEFEF;
}

.bottomSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  font-size: 14px;
  color: #939393;
}

.entitySection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.noteSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 10px;
}

.userPill{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 8px;
  background: #F2F4F7;
  border-radius: 20px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #939393;
  margin-right: 8px;
}
