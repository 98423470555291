.header {
  display: flex;
  line-height: 16px;
  justify-content: space-between;
}

.roleTitle {
  color: var(--color-gray-700);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin: 4px 0;
}

.tags {
  display: flex;
  flex-wrap: wrap;
}
