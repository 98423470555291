.main {
  padding: 8px 16px 8px 20px;
  gap: 16px;
  border-bottom: var(--border-gray-1);
  background: #fff;
  cursor: pointer;
  position: relative;
  flex-shrink: 0;
  display: grid;
  grid-template-columns: 26px 1fr 20px;
}

.main[data-unread='true']:before {
  content: '';
  position: absolute;
  width: 4px;
  height: 4px;
  background-color: #e6466c;
  top: 20px;
  border-radius: 50%;
  left: 10px;
}

.main button {
  background-color: white!important;
}

.selected {
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 100%;
}

.selected[data-selected='true'] {
  background-color: #6731d3;
}

.main:hover,
.main:hover button {
  background-color: #f9fafb!important;
}

.icon {
  display: flex;
  width: 28px;
  height: 28px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: 19px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  background: #fff;
}

.info {
  display: flex;
  flex-direction: column;
}

.infoTime {
  color: var(--color-gray-500);
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 13px;
}

.infoMain {
  color: var(--color-black);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.infoSecondary {
  overflow: hidden;
  color: var(--color-gray-600);
  text-overflow: ellipsis;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
}

.menu {
  display: flex;
  background: var(--color-white);
  border: 0.549518px solid #efefef;
  box-sizing: border-box;
  box-shadow: 0px 2.19807px 4.39614px rgba(0, 0, 0, 0.06);
  border-radius: 2.19807px;
  flex-direction: column;
  position: relative;
  font-size: 14px;
  z-index: 200;
  border-radius: 8px;
  max-width: 280px;
}

.menuItem {
  padding: 8px 12px;
  color: var(--Gray-Gray-900, #13191f);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.menuItem:hover {
  cursor: pointer;
  background-color: #f9fafb;
}

.reason {
  padding: 8px 12px;
  color: #656a70;
  background-color: #f3f4f7;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
