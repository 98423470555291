.button {
  background: white;
  border: 1px solid var(--color-gray-50);
  border-radius: 2px;
  padding: 4px 15px;
  color: var(--color-gray-700);
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  font-weight: 500;
  font-size: 14px;
}

.button[data-status="active"] {
  background: var(--color-connected-background);
  border: 1px solid var(--color-connected-text);
  color: var(--color-connected-text);
}

.button[data-type='icon-button'] {
  padding: 7px;
}

.button:disabled {
  background: var(--color-gray-10);
  border: 1px solid var(--color-gray-50);
  cursor: not-allowed;
  pointer-events: none;
}

.button:hover {
  background: var(--color-gray-100);
  border: 1px solid var(--color-gray-200);
}

.button:focus {
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #D6C5F7;
}

.icon {
  font-size: 0;
}

.button[data-type='text-button'] .icon[data-position="leading"] {
  padding-right: 8px;
}

.button[data-type='text-button'] .icon[data-position="trailing"] {
  padding-left: 8px;
}