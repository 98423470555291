.card {
  padding: 10px;
  border-radius: 8px;
  border: 1px solid var(--color-gray-75);
  background: var(--color-white);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  transition: filter 0.2s;
  white-space: wrap;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: space-between;
}

.header p {
  margin: 0;
  color: var(--color-gray-700);
  white-space: pre-wrap;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.divider {
  height: 1px;
  width: calc(100% + 24px);
  margin-top: 12px;
  margin-left: -12px;
  margin-bottom: 8px;
  background-color: #eaecf0;
}

.date {
  color: var(--color-gray-200);

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.editIcon {
  width: 18px;
  height: 12px;
  position: relative;
  border: none;
  opacity: 0.5;
}

.deleteIcon {
  width: 18px;
  height: 12px;
  position: relative;
  border: none;
  opacity: 0.5
}

.menu {
  display: flex;
  background: var(--color-white);
  border: 0.549518px solid #efefef;
  box-sizing: border-box;
  box-shadow: 0px 2.19807px 4.39614px rgba(0, 0, 0, 0.06);
  border-radius: 2.19807px;
  margin: 16px;
  flex-direction: column;
  position: relative;
  font-size: 14px;
  z-index: 200;
}

.menuItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 18px 10px 18px;
  border-bottom: 1px solid #efefef;
  gap: 8px;
  background-color: var(--color-white);
}

.menuItem:hover {
  cursor: pointer;
  filter: brightness(98%);
}
