.main {
  padding: 8rem 3rem 3rem;
  width: 100%;
  min-height: calc(100vh - var(--height-header));
  margin: 0 auto;
  max-width: 850px;
}

.title {
  font-size: 4rem;
  font-weight: 700;
  line-height: 4rem;
  color: var(--text-black);
  margin: 0 0 1rem 0;
}

.subtitle {
  font-size: 2rem;
  font-weight: bold;
  line-height: 2.5rem;
  margin: 0 0 7rem 0;
  color: var(--color-gray-50);
}

.topic {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 auto 9rem;
}

.topic[data-status='unsubscribed'] {
  opacity: 0.3;
}

.flag {
  height: 27px;
  width: 36px;
  object-fit: cover;
  border: 1px solid var(--color-black);
}

.details {
  flex-grow: 1;
  margin-left: 20px;
}

.topicTitle {
  color: var(--text-black);
  font-size: 2rem;
  font-weight: bold;
  line-height: 2.5rem;
  margin: 0;
}

.entities {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.entity {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  color: var(--text-black);
  font-size: 1.6rem;
  line-height: 1.6rem;
}

.submit {
  margin-top: 3rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.button {
  border: none;
  cursor: pointer;
  padding: 1.4rem 3rem;
  text-align: center;
  border-radius: 2px;
  max-width: 100%;
  background-color: var(--color-purple-600);
  color: var(--color-white);
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 2.3rem;
  margin-bottom: 3rem;
}

@media(max-width: 950px) {
  .main {
    height: calc(100vh - var(--height-header));
    padding-top: 3rem;
  }

  .entities {
    flex-direction: column;
    align-items: flex-start;
  }

  .entity {
    font-size: 1.3rem;
    line-height: 1.3rem;
  }
}