.main {
  padding-top: 120px;
  margin: 0 auto 40px;
  max-width: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.logo {
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
}

.title {
  text-align: center;
}

.subtitle {
  margin-bottom: 30px;
  font-weight: normal;
}

.button {
  background-color: transparent;
  padding: 12px 32px;
  border: 1px solid var(--color-main-dark);
  border-radius: 4px;
  color: var(--color-main-dark);
  cursor: pointer;
  font-size: 16px;
}

.button:hover {
  color: white;
  background-color: var(--color-main-dark);
  transition: all .5s;
}

.actions {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}