.title {
  font-size: 16px;
}

.footer {
  display: flex;
  justify-content: space-between;
  padding: 8px !important;
}

.buttons {
  display: flex;
  align-items: center;
  gap: 4px;
}

.button {
  border: none;
  background-color: transparent;
  outline: none;
  padding: 4px 14px;
  cursor: pointer;
  color: var(--color-gray-600);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.button[data-compliant='true'] {
  color: var(--color-green-700);
  background-color: var(--color-green-25);
}

.button[data-compliant='true'] path {
  fill: var(--color-green-700);
}

.button[data-compliant='true']:hover {
  background-color: var(--color-green-25);
  filter: brightness(0.98);
}

.button:hover {
  background-color: var(--color-gray-10);
}

.divider {
  width: 1px;
  height: 25px;
  background-color: var(--color-gray-50);
}

.root[data-size='expanded'] {
  max-width: 100%;
  border: 1px solid var(--color-gray-20);
}

.root[data-size='normal'] {
  border: 1px solid var(--color-gray-20);
}

.root[data-size='normal'] > div:nth-child(1) {
  flex-direction: column !important;
}

.root[data-size='normal'] > div:nth-child(1) > div:nth-child(2) {
  border-left: none;
  border-top: 1px solid var(--color-gray-20);
}
