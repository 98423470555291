.sources {
  display: flex;
  align-items: center;
}

.smallScreen {
  display: block;
}

.largeScreen {
  display: none;
}

@container myComponent (min-width: 320px) {
  .smallScreen {
    display: none;
  }

  .largeScreen {
    display: flex;
    gap: 4px;
  }
}

.sourcesInMenu {
  display: flex;
  height: 22px;
  padding: 2px 6px;
  justify-content: center;
  align-items: center;
  gap: 2px;
  border-radius: 4px;
  background: #f3f4f6;

  color: #9ca3ab;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  cursor: pointer;
}

.menu {
  background-color: white;
  border-radius: 4px;
  border: 1px solid #f9fafb;
  list-style: none;
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
    0px 2px 4px -2px rgba(16, 24, 40, 0.06);
}

.menu li {
  background-color: white;
  padding: 8px 12px;
  color: #929aa3;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.menu li:not(:first-child) {
  border-top: 1px solid #e5e7eb;
}

