.main {
  padding: 6px 16px;
  border-radius: 8px;
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-75);
}

.header {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;
  cursor: pointer;
}

.chevron path {
  fill: var(--color-gray-700);
}

.chevron {
  transition: 0.2s;
}

.main[data-is-open='true'] .chevron {
  transform: rotate(180deg);
}

.steps {
  gap: 1rem;
  display: flex;
  flex-direction: column;
  color: var(--color-gray-500);
  height: 0;
  overflow: hidden;
  transition: height 0.5s ease-in-out;
}

.main[data-is-open='true'] .steps {
  height: 160px;
}

.step {
  display: flex;
  align-items: center;
  gap: 4px;

  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.step:first-child {
  margin-top: 1rem;
}

.tagExtraSpace {
  margin-left: 2px;
}

.question {
  margin-left: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.questiontitle  {
  display: flex;
  gap: 4px;
  flex-direction: row;
  flex-shrink: 0;
}

.steps .spin {
  animation: infinite 0.7s spin linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
