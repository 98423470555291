.searchIcon {
  width: 12px;
  height: 12px;
  position: relative;
}

.actionWrapper {
  display: flex;
  padding: 5px;
}

.trashIcon {
  width: 20px;
  height: 13px;
  position: relative;
  border: none;
  align-items: center;
}

.editIcon {
  width: 18px;
  height: 12px;
  position: relative;
  border: none;
  margin-right: 5px;
  opacity: .5;
}

.moreIcon {
  width: 18px;
  height: 12px;
  position: relative;
  border: none;
  margin-right: 5px;
  opacity: .5;
}

.homeIcon {
  width: 18px;
  height: 12px;
  position: relative;
  border: none;
  margin-right: 5px;
  opacity: .5;
}

.exploreIcon {
  width: 20px;
  height: 20px;
}

.savedSearchCard {
  display: flex;
  flex-direction: column;
  border: 0.5px solid #D1D5DB;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  width: 100%;
}

.savedSearchCard:nth-child(n+2) {
  margin-top: 10px;
}

.savedSearchCard:hover {
  background: #F3F4F6;
  cursor: pointer;
}

.savedSearchCardHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--color-gray-25);
}

.savedSearchCardHeaderLeft {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 70%;
  max-width: 70%;
  padding: 10px;
}

.savedSearchCardHeaderRight {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
}

.savedSearchCardHeaderTitle {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  margin-left: 10px;
  color: var(--color-black);
}

.savedSearchCardBody {
  display: flex;
  padding: 0 0 15px 0;
  flex-wrap: wrap;
  padding: 5px;
}

.textSearch {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  padding: 0px 5px;
}

.menu {
  display: flex;
  background: var(--color-white);
  border: 0.549518px solid #EFEFEF;
  box-sizing: border-box;
  box-shadow: 0px 2.19807px 4.39614px rgba(0, 0, 0, 0.06);
  border-radius: 2.19807px;
  margin: 16px;
  flex-direction: column;
  position: relative;
  font-size: 14px;
  z-index: 200;
}

.optionsItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 18px 10px 18px;
  border-bottom: 1px solid #EFEFEF;
}

.optionsItem:hover {
  cursor: pointer;
  opacity: 1;
}

.optionsItemRemoval {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 18px 10px 18px;
  border-bottom: 1px solid #EFEFEF;
  color: red;
}

.optionsItemRemoval:hover {
  cursor: pointer;
  opacity: 1;
}

.homeFeed {
  margin: 10px 18px 10px 10px;
  padding: 5px 2px 5px 6px;
  border: 1px solid #B2DDFF;
  font-size: 12px;
  border-radius: 4px;
  width: 77px;
  background-color: #EFF8FF;
  color: #175CD3;
}