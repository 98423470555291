.main {
  ---padding-home: 22px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  position: relative;
  gap: 22px;
  will-change: transform3d;

  padding-block: var(---padding-home);
  container-name: appMainContainer;
  container-type: inline-size;
}

.input {
  max-width: 600px;
}

.welcome {
  position: absolute;
  top: var(---padding-home);
  left: var(---padding-home);
}

.pdb {
  width: 100%;
  padding-inline: var(---padding-home);
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr auto;

}

.treeMap {
  width: 100%;
  padding-inline: var(---padding-home);
  isolation: isolate;
}

.space {
  width: 100%;
  padding-left: 22px;
  padding-right: 22px;
  display: flex;
  justify-content: center;
}

@container appMainContainer (max-width: 890px) {
  .pdb {
    grid-template-columns: 1fr;
  }

  .pdb h2 {
    column-count: 1 !important;
  }
}
