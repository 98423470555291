.main {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.question {
  color: var(--color-gray-800);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}

.switcher {
  color: var(--color-gray-800);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
}

.switcher button {
  color: #6731D3;
}
