.main {
  width: 0;
  transition: width 300ms;
  background-color: var(--color-white);
  white-space: nowrap;
  grid-row: left-panel;
  position: sticky;
  top: 0;
  height: 100dvh;
}

.main[data-open='true'] {
  border-right: 1px solid var(--color-gray-75);
  grid-column: 1/-1;
  width: 100%;
}

.main[data-active='true'] {
  z-index: 10;
}

.main > div {
  height: 100%;
}

@media (min-width: 40rem) {
  .main[data-open='true'] {
    grid-column: left-panel;
    width: var(--width-left-panel);
  }
}
