.card {
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;
  background: var(--color-white);
  box-sizing: border-box;
  border: 1px solid var(--color-gray-20);
  border-radius: 2.19807px;
  margin-bottom: -1px;
  position: relative;
}

.title {
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  margin: 0 0 1rem 0;
  cursor: pointer;
}

.title:hover {
  color: var(--color-purple-800);
}

.date {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: var(--color-gray-400);
  margin-bottom: 0.5rem;
}

.entities {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;
}

.entities > div {
  margin-left: -2px;
  margin-right: 6px;
}

.briefs {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;
}

.source {
  font-weight: 400;
  font-size: 12px;
  color: var(--color-gray-400);
  margin-bottom: 0;
}

.link {
  color: var(--color-gray-100);
  margin-right: 24px;
  display: inline-flex;
  align-items: center;
}

.linkIcon {
  width: 14px;
  height: 14px;
  margin-right: 8px;
}

.link:hover {
  color: var(--color-purple-600);
}

.link:hover .linkIcon {
  --color-source: var(--color-purple-600);
}

.action {
  display: flex;
  justify-content: center;
  height: 32px;
  bottom: 1rem;
  right: 1.5rem;
  position: absolute;
}

.note {
  display: flex;
  justify-content: center;
  height: 32px;
  min-width: 120px;
  max-width: 130px;
}

.quote {
  display: flex;
  justify-content: center;
  height: 32px;
  min-width: 120px;
  max-width: 130px;
  margin-left: 5px;
}

.note[data-location='dossier-event-card'] {
  position: unset;
  bottom: unset;
  right: unset;
}

.quote[data-location='dossier-event-card'] {
  position: unset;
  bottom: unset;
  right: unset;
}

.secondary {
  display: flex;
  gap: 12px;
  align-items: center;
  margin-bottom: 4px;
}

.secondary p {
  margin: 0;
}

.neSource {
  width: fit-content;
  background-color: var(--color-yellow-200);
  color: var(--color-yellow-800);
  font-size: 11px;
  border-radius: 12px;
  font-weight: 600;
  padding: 2px 8px;
}
