.pill {
  padding: 2px 8px 2px 3px;
  color: var(--color-purple-800);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  gap: 4px;
  border-radius: 16px;
  border: 1px solid var(--color-purple-200);
  background: var(--color-purple-50);
  display: flex;
  align-items: center;
}

.pill img {
  width: 16px;
  height: 16px;
  border-radius: 50%;
}

.pill[data-type="Context"] {
  background-color: var(--color-cyan-100);
}

.pill[data-type="Plot"] {
  color: var( --color-pink-800);
  background-color: var(--color-pink-70);
 border-color: var(--color-pink-100);
}
