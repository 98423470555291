.main {
  padding: 12px 12px 12px 16px;
  border-radius: 6px;
  border: 1px solid var(--color-gray-75);
  display: flex;
  cursor: pointer;
  background-color: white;
  width: 100%;
}

.main:hover {
	border-color: #929AA3;
}

.main[aria-pressed="true"] {
  outline: 2px solid var(--color-purple-600);
  outline-offset: -2px;
}

.name {
  display: flex;
  flex: 1;
  color: var(--color-gray-800);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-wrap: nowrap;
  margin-right: 1ch;
}

.count {
  color: var(--color-gray-500);
  text-align: right;
  text-wrap: nowrap;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.icon {
  width: 20px;
  height: 20px;
  flex: none;
}

.icon g path {
  fill: var(--color-gray-500);
}
