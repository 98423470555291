.emptyState {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-block: 4rem;
}

.emptyIcon {
  display: flex;
  width: 44px;
  height: 44px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex-shrink: 0;
  border-radius: 6px;
  background: var(--color-gray-75);
  margin-bottom: 16px;
}

.emptyState div:nth-child(2) {
  color: #13191f;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 4px;
}

.emptyState div:nth-child(3) {
  color: var(--color-gray-600);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.loadingState {
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 8px;
}

.option {
  width: 100%;
  padding: 8px 12px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  cursor: pointer;
}

.option input {
  cursor: pointer;
}

.option:hover {
  background-color: #f3f4f6;
}
