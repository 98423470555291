.activityItem {
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid #e5e7eb;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
}

.activityItem[data-disabled='false']:hover {
  background-color: #f9fafb;
}

.activityItem[data-disabled='true'] {
  display: none;
}

.activityContent {
  display: flex;
  flex-direction: row;
  gap: 4px;
}

.activityTitle {
  color: #000;
  font-size: 15px;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 20px;
  letter-spacing: 0.5px;
}

.activity {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
}

.loading {
  padding: 0 16px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 8px;
  height: 180px;
  gap: 8px;
}
