.container {
  position: absolute;
  top: 0;
  left: 0;
  flex-direction: row;
  width: 100vw;
  min-height: 100vh;
  overflow: hidden;
  background-color: var(--color-white);
}
