.header {
  display: flex;
  justify-content: space-between;
}

.header h2 {
  color: #101828;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.closeBtn {
  height: fit-content;
  border-radius: 50%;
  display: flex;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  background-blend-mode: multiply;
}

.closeBtn:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.closeBtn svg {
  width: 24px;
  height: 24px;
}
