.main {
  height: inherit;
  overflow: scroll;
  scroll-behavior: smooth;
}

.header {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: white;
}

.divider {
  z-index: 5;
  width: 100%;
  height: 1px;
  flex-shrink: 0;
  margin-top: -5px;
  position: relative;
  background-color: var(--color-gray-75);
}

.content {
  display: flex;
  flex-direction: column;
}

.content[data-expanded='true'] {
  padding: 0 60px;
}

.content[data-hidden=true] {
  display: none;
}


.errorMain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.errorLogo {
  width: 32px;
  height: 32px;
  margin-bottom: 16px;
}

.errorTitle {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
}

.errorDescription {
  font-size: 14px;
}

.loadingState {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding:16px;
}
