.loading {
  width: 50px;
}

.loadingWrapper{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}