.main {
  padding-top: 16px;
  padding-bottom: 24px;
  margin-right: -32px;
  height: auto !important;
  width: auto !important;
  grid-area: chart;
}

.loading {
  margin-top: 16px;
  grid-area: chart;
}

.node {
  cursor: pointer;
}
.node:hover {
  opacity: 1;
}
