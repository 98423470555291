.errorMain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
}

.errorLogo {
  width: 32px;
  height: 32px;
  margin-bottom: 16px;
}

.errorTitle {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
}

.errorDescription {
  font-size: 14px;
}
