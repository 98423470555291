.main {
  padding: 12px 16px;
  border-bottom: 1px solid var(--color-gray-75);
  background: var(--color-white);
	cursor: pointer;
}

.title {
  font-size: 14px;
}
