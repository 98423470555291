.main {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.label {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: var(--color-gray-400);
  margin-left: 8px;
}