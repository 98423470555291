.formContainer {
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
}

.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2.25rem;
  margin-bottom: 7.125rem;
}

.logoContainer > svg {
  height: 60px;
  max-width: 100%;
}

.form {
  width: 42.6875rem;
  max-width: 100%;
  padding: 2rem;
}

.title {
  font-size: 4rem;
  font-weight: 700;
  line-height: 4rem;
  color: var(--text-black);
  margin: 0 0 1rem 0;
}

.subtitle {
  font-size: 2rem;
  font-weight: bold;
  line-height: 2.5rem;
  margin: 0 0 2rem 0;
  color: var(--color-gray-50);
}

.inputContent {
  margin-bottom: 3rem;
}

.button {
  border: none;
  cursor: pointer;
  padding: 1.4rem;
  text-align: center;
  border-radius: 80px;
  max-width: 100%;
  width: 42.6875rem;
  background-color: var(--text-black);
  color: var(--color-white);
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 2.3rem;
  margin-bottom: 3rem;
}

.button:disabled {
  background-color: var(--color-gray-50);
  cursor: not-allowed
}

.footer {
  padding: 2rem;
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
}

@media(max-width: 768px) {
  .headerHelp {
    display: none;    
  }
}

@media(min-width: 769px) {
  .footerHelp {
    display: none;    
  }
}
