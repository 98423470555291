.sidebar {
  background-color: var(--color-white);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  z-index: 3;
  position: sticky;
  top: 0;
  display: none;
  height: 100dvh;
}

.sidebar[data-status='expanded'] {
  display: block;
  position: fixed;
  width: 100%;
}

.main {
  width: 100%;
  flex-shrink: 0;
  padding: 16px;
  user-select: none;
  overflow: scroll;
  max-height: calc(100svh - 102px);
  /* 102px = header + footer size */
}

.divider {
  width: 100%;
  height: 1px;
  background: #f3f4f6;
}

.dividerSpaced {
  width: 100%;
  height: 1px;
  background: #f3f4f6;
  margin-top: 16px;
  margin-bottom: 16px;
}

.spacing {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.dateFilter {
  margin-top: 0 !important;
}

.industryFilter,
.industryFilter > div:first-child {
  margin-top: 0 !important;
}

.countryFilter {
  margin-bottom: 0 !important;
}

.countryFilter > div:first-child {
  margin-top: 0;
}

.searchActor {
  margin-bottom: 0;
}

.searchActor > div:first-child {
  margin-top: 0;
}

.buttons {
  position: absolute;
  bottom: 0;
  display: flex;
  padding: 12px;
  gap: 12px;
  width: 100%;
  border-top: 1px solid var(--color-gray-75);
}

.buttons button {
  flex: 1;
}

/* desktop */
@media (min-width: 40rem) {
  .sidebar[data-status='expanded'] {
    position: sticky;
    top: 0;
    display: initial;
    flex-grow: 0;
    flex-basis: var(--width-filters);
  }
}
