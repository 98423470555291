.dossier {
  height: calc(100vh - var(--height-header));
  overflow-y: scroll;
  overflow-x: hidden;
}

.images {
  margin-right: 8px;
  display: flex;
}

.header {
  padding-left: 2rem;
  opacity: 1;
  z-index: 3;
  position: relative;
  transition: 150ms ease-in-out;
}

.title {
  display: flex;
  align-items: center;
  z-index: 3;
  margin-top: 2rem;
  margin-left: 2rem;
  margin-bottom: 2rem;
  position: relative;
  background-color: white;
  justify-content: center;
}

.titleText {
  font-size: 2.75rem;
  font-weight: bold;
  line-height: 1.875rem;
}

.avatar {
  margin-left: -1.5rem;
}

.content {
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  padding-bottom: 6rem;
}

.entities {
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
}

.line {
  border: 1px solid var(--color-gray-25);
}

.container {
  margin: 16px;
}

.empty {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: var(--color-gray-400);
  margin: 20px 0;
  padding: 0 20px
}

.range-container {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.date {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-gray-20);
  color: var(--color-gray-600);
  font-weight: 700;
  font-size: 11px;
  line-height: 14px;
  border-radius: 4px;
}

.text {
  color: var(--color-gray-700);
  margin: 3px 5px;
}

.calendar {
  width: 14px;
  height: 14px;
  margin-left: 6px;
}