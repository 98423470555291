.root {
  overflow-y: hidden;
  height: 100vh;
  width: 100%;
}

.container {
  margin: 0 auto;
  height: inherit;
  display: flex;
  justify-content: space-between;
}

.content {
  flex: 1;
  margin: 0;
  z-index: 1;
  overflow: auto;
  background-color: #F9FAFB;
  padding: 0 60px;
}

.content[data-status='expanded'] {
  padding: 0 2rem;
}

@media (max-width: 1450px) {
  .content {
    padding: 0 20px;
  }
}
