/* sticky container takes up place for content */
.container {
  position: sticky;
  top: 0;
  left: 0;
  width: 0;
  height: 100dvh;
  grid-area: side-nav;
}

.main {
  --icon-margin: 6px;
  --icon-size: 32px;
  display: none;
  flex-direction: column;
  padding: 16px 0;
  background: var(--color-white);
  border-right: 1px solid var(--color-gray-75);

  width: inherit;
  height: inherit;
  overflow-y: auto;
}

.logo {
  margin: 8px 16px 8px 16px;
  cursor: pointer;
}

.explore {
  display: flex;
  gap: 8px;
  padding: 8px 20px;
  border: 1px solid transparent;
  border-left: none;
  border-right: none;
}

.explore:nth-child(2) {
  margin-top: 32px;
  margin-bottom: 4px;
}

.explore:hover {
  background: var(--Color-4, rgba(23, 22, 25, 0.04));
}

.explore[data-active='true'] {
  border: 1px solid var(--color-purple-300);
  border-left: none;
  border-right: none;
  background: #f4ebff;
}

.explore[data-active='true'] h3 {
  color: var(--color-purple-700);
}

.explore[data-active='true'] svg path {
  fill: var(--color-purple-700);
}

.itemTitle {
  color: var(--color-gray-600);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.divider {
  width: 100%;
  height: 1px;
  margin: 16px 0;
  background: rgba(23, 22, 25, 0.1);
}

.halfDivider {
  height: 1px;
  margin: 24px 12px;
  background: rgba(23, 22, 25, 0.1);
}

.bottomIcons {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
}

.wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 8px;
  margin: 0 12px;
  padding: 8px;
  border-radius: 6px;
  margin-bottom: 4px;
  position: relative;

  --icon-color: #42474d;
  --background-color: transparent;
  --text-color: var(--color-gray-600);

  background-color: var(--background-color);
}

.wrapper h3 {
  color: var(--text-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.sideButtonIcon {
  display: flex;
}

.sideButtonIcon svg {
  width: 20px;
  height: 20px;
}

.sideButtonIcon > svg {
  color: var(--icon-color);
}

/* On Hover Wrapper */
.wrapper:hover {
  --background-color: rgba(23, 22, 25, 0.04);
}

/* On Active Wrapper */
.wrapper[data-active='true'] {
  --background-color: var(--color-purple-300);
  --text-color: var(--color-purple-700);
  --icon-color: var(--color-white);
}

/* On Active Wrapper */
.wrapper[data-active='true'][data-dock='true'] {
  --background-color: #f4ebff;
  --text-color: var(--color-purple-700);
  --icon-color: var(--color-purple-700);
}

/* mobile ui */
/*
  on desktop we have height-mobile-nav
  on mobile we have:
  - height for svh
  - height+offset for lvh (to obscure the gap between nav & screen bottom)
  - height+offset/2 for dvh (to give a slight height boost when url bar is not shown, but home bar button is visible, and may obscure button labels)
*/

.mobileNav {
  --height: calc(var(--height-mobile-nav) + env(safe-area-inset-bottom));
  --offset: calc(-1 * env(safe-area-inset-bottom));
  --offset-half: calc(var(--offset) * 0.5);

  grid-area: mobile-nav;
  position: sticky;
  z-index: 20;
  bottom: var(--offset-half);
  margin-bottom: var(--offset);
  background-color: var(--color-white);
}

/* dossier is opened */
.mobileNav:has(~ * > [id='dossier'][data-active='true']) {
  visibility: hidden;
}

.mobileButtons {
  width: 100%;
  height: 100%;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(40px, 1fr));
  align-items: center;
  justify-content: stretch;
}
.mobileButtons li {
  height: 100%;
}

.mobileButton {
  height: var(--height);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  padding: 8px 8px 4px;
  font-size: 11px;
  font-weight: 500;
  margin: auto;
  /* color: var(--color-pink); */
  color: var(--color-gray-600);
  min-width: fit-content;
  border-top: 1px solid var(--color-gray-75);
}

.mobileButton > * {
  height: 20px;
}

.mobileButton svg[data-active='false'] {
  color: var(--color-gray-500);
}

.mobileButton[data-active='true'] {
  border-top: 1px solid var(--color-purple-600);
  color: var(--color-purple-600);
  background-color: var(--color-purple-50);
}

.avatar {
  border-radius: 100%;
  border: 1px solid color-mix(in srgb, var(--color-gray-600) 8%, transparent);
  color: var(--color-purple-600);
  background-color: var(--color-purple-50);
  width: 20px;
  height: 20px;
  font-weight: 700;
  font-size: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobileBadge {
  position: relative;

  &.mobileBadge div {
    top: -6px;
    left: 9px;
    border: 1px solid var(--color-white);
    line-height: 14px;
  }
}

.profileTabs {
  background: var(--color-white);
  display: flex;
  gap: 16px;
  padding-inline: 16px;
  list-style: none;
  overflow-y: hidden;
  overflow-x: auto;
  border-bottom: 1px solid var(--color-gray-75);
  height: var(--height-profile-tabs);
  grid-area: profile-tabs;
}

.profileTab {
  color: var(--color-gray-muted);
  padding: 8px 0px 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-wrap: nowrap;
}

.profileTab[data-active='true'] {
  color: var(--color-black);
  border-bottom: 1px solid var(--color-gray-800);
  margin-bottom: -1px;
}

/* desktop */
@media (min-width: 40rem) {
  .mobileNav,
  .profileTabs {
    display: none;
  }
  .container {
    width: var(--width-sidenav);
  }
  .main {
    display: flex;
  }
}
