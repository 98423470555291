.main {
  border: none;
  outline: none;
  padding: 6px 8px;

  color: #42474d;
  text-align: center;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: center;
  text-wrap: nowrap;
}

.main:hover,
.main:focus {
  filter: brightness(95%);
}

.main[data-type='secondary'] {
  border: 1px solid #e5e7eb;
  background-color: var(--color-white);
}

.main[data-type='danger'] {
  border: 1px solid var(--color-red-600);
  background: var(--color-red-600);
  color: var(--color-white);
}

.main[data-type='primary'] {
  border: 1px solid var(--color-purple-600);
  background: var(--color-purple-600);
  color: var(--color-white);
}

.main[data-type='success'] {
  border: 1px solid var(--color-connected-text);
  background: var(--color-green-25);
  color: var( --color-green-700);
}

.main[data-type='ghost'] {
  border: none;
  color: var(--color-purple-600);
  background-color: var(--color-white);
}

.main[data-type='danger']:hover,
.main[data-type='primary']:hover,
.main[data-type='danger']:focus,
.main[data-type='primary']:focus {
  filter: brightness(90%);
}
