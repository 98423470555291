.main {
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.container {
  width: 7rem;
  border-radius: 18.25rem;
  border: 1px solid var(--gray-icon);
}

.dot {
  margin: 0.25rem;
  height: 3rem;
  width: 3rem;
  background: var(--color-gray-50);
  border-radius: 50%;
  transition: margin 0.25s ease-in-out;
}

.text {
  color: var(--text-dark);
  margin: 0 0 0 1.5rem;
  font-size: 1.75rem;
  font-weight: 500;
  line-height: 2.3rem;
}

.main[data-status='checked'] .container {
  background: var(--bg-gray);
  border-color: var(--bg-gray);
}

.main[data-status='checked'] .dot {
  margin-left: 3.313rem;
  background-color: var(--color-white);
}

.main[data-status='checked'] .text {
  color: var(--bg-gray);
}