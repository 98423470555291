.app {
  width: 100%;
  min-height: 100dvh;

  padding-bottom: env(safe-area-inset-bottom);
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
  padding-top: env(safe-area-inset-top);

  font-size: 2rem;
  background-color: var(--color-bg);
  position: relative;

  display: grid;
  grid-template: auto 1fr auto / auto auto 1fr auto;
  grid-template-areas:
    'profile-tabs profile-tabs profile-tabs profile-tabs'
    'side-nav left-panel content dossier'
    'mobile-nav mobile-nav mobile-nav mobile-nav';
}

.routerOutlet {
  background-color: var(--color-bg);
  grid-row: content;
  grid-column: content / dossier;
  display: grid;
  grid-template-rows: subgrid;
  grid-template-columns: subgrid;
}
