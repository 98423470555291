.card {
  background: var(--color-white);
  border: 1px solid var(--color-gray-25);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  margin: 0 10px 20px;
}

.title {
  padding: 9px 16px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--color-gray-25);
}

.titleInfo {
  display: flex;
  align-items: center;
}

.cardTitle {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: var(--color-gray-900);
  margin: 0 16px 0 0;
  display: flex;
  gap: 8px;
}

.cardTotal {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: var(--color-gray-100);
  margin: 0;
}

.pageInfo {
  display: flex;
  align-items: center;
}

.pageCount {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-gray-100);
  margin: 0 4px;
}

.loadingArea {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
}

.loading {
  width: 50px;
}