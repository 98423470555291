.header {
  display: flex;
  gap: 8px;
  flex-direction: column;
  justify-content: space-between;
  align-items: baseline;
  position: relative;
  padding: 12px 0px 12px 0px;
}

.title {
  font-weight: bold;
  font-size: 14px;
  color: var(--text-black);
  cursor: default;
  user-select: none;
  margin: 0;
  flex: 1;
  line-height: 200%;
  display: block;
  /* overflow: hidden;
  text-overflow: ellipsis; */
  white-space: nowrap;
}

.titleHighlight {
  color: var(--color-gray-400);
  background-color: var(--color-gray-25);
  padding: 3px 5px;
  font-weight: bold;
}

.titleFilters {
  position: relative;
}

.titleFilters:hover>.titleFiltersPopover {
  display: flex;
}

.titleFiltersPopover {
  display: none;
  position: absolute;
  top: calc(100% + 5px);
  left: -10px;
  flex-direction: column;
  align-items: flex-start;
  white-space: nowrap;
  box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  background-color: white;
  padding: 5px 10px;
  z-index: 2;
}

.titlePopoverHighlight {
  margin: 5px;
  text-transform: capitalize;
}

.titleDatesHighlight {
  margin: 5px;
}

.saveSearchContainer {
  background: #fafafa;
}

.setAsHomeFeed {
  right: 0;
  top: 10px;
  padding: 6px 10px;
  margin-right: 10px;
  border: var(--border-gray-1);
  border-radius: 4px;
  background: #FDFDFF;
}

.seeGraph{
  right: 0;
  top: 10px;
  padding: 6px 10px;
  margin-right: 10px;
  border: var(--border-gray-1);
  border-radius: 4px;
  background: #FDFDFF;
}

.seeGraph[data-disabled=true] {
  pointer-events: none;
  opacity: 0.7;
  background: var(--color-gray-20);
}

.saveSearch {
  right: 0;
  top: 10px;
  padding: 6px 10px;
  border: var(--border-gray-1);
  border-radius: 4px;
  background: #FDFDFF;
}

.saveSearch[data-status="saved"] {
  pointer-events: none;
  background: #F6FEF9;
  border: 1px solid var(--color-success-400);
  color: var(--color-success-400);
}

.saveSearch:hover, .setAsHomeFeed:hover, .seeGraph:hover  {
  background: #F2F2F2;
  cursor: pointer;
}

.homeIcon {
  width: 18px;
  height: 12px;
  position: relative;
  border: none;
  margin-right: 1px;
}

.homeFeed[data-feed-action="remove"] {
  color: #cc0909
}

.homeFeed[data-feed-action="set"] {
  color: black;
}

.networkIcon {
  width: 18px;
  height: 12px;
  position: relative;
  border: none;
  margin-right: 1px;
}

.main {
  display: flex;
  flex: 1;
  width: 100%;
  flex-wrap: wrap;
  gap: 8px
}

.switcher {
  color: var(--color-gray-800);
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
}

.switcher button {
  color: #6731D3;
}
