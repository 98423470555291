.main {
  transition: opacity 0.3s ease-in-out;
  position: absolute;
  align-self: flex-start;
  user-select: none;
  left: 0px;
  top: 0px;
  padding: 10px;
  z-index: 1;
}

.main[data-status="open"] {
  background:white;
  height: 100svh;
  padding: 0;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: 0px 0px 4px 0px;
  width: 340px;
}

.open {
  border: solid 1px var(--color-gray-700);
  border-radius: 6px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  padding: 7px 6px 5px;
  background-color: transparent;
  cursor: pointer;
}

.open:hover {
  background-color: var(--color-gray-25);
}

.openIcon {
  width: 18px;
  height: 18px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 6px;
}

.headerTitle {
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.filterIcon {
  width: 18px;
  height: 18px;
  flex-shrink: 0;
  margin-right: 14px;
}

.title {
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: var(--color-gray-700);
  flex-shrink: 0;
}


.close {
  cursor: pointer;
  background-color: transparent;
  border: none;
}

.closeIcon {
  width: 24px;
  height: 24px;
}


.filters {
  padding: 16px;
  border: none;
  max-height: calc(100svh - 115px);
  overflow: scroll;
}

.toggleButton {
  width: 2.25rem;
  cursor: pointer;
  height: 2.25rem;
  padding: 0.75rem;
  border-radius: 0.5rem;
  box-sizing: content-box;
  border: var(--border-gray-1);
  box-shadow: var(--shadow-lg);
  background-color: var(--color-white);
}

.toggleButton:hover {
  filter: brightness(0.97);
}

.toggleButton > svg {
  width: 2.25rem;
  height: 2.25rem;
  fill: var(--color-gray-900);
}

.buttons {
  position: absolute;
  bottom: 0;
  display: flex;
  padding: 12px;
  gap: 12px;
  width: 100%;
  border-top: 1px solid var(--color-gray-75);
}

.buttons button {
  flex: 1;
}

.divider {
  width: 100%;
  height: 1px;
  background: #f3f4f6;
  margin: 16px 0;
}