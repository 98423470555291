.pill {
  border-radius: 4px;
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  color: var(--color-gray-600);

  padding: 2px 4px;
  cursor: pointer;

  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
}

.pill:hover {
  color: var(--color-purple-800);
}
