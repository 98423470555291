.container {
  height: 3.25rem;
  display: flex;
  font-size: 2rem;
  align-items: center;
  padding: 16px;
  /* box-sizing: content-box; */
  justify-content: space-between;
  border-bottom: var(--border-gray-1);
}

.container > div {
  gap: 1rem;
  display: flex;
  align-items: center;
}

.filterIcon {
  width: 2rem;
  height: 2rem;
  margin-left: 0.5rem;
  fill: var(--color-gray-900);
}

.closeIcon {
  width: 1.4rem;
  height: 1.4rem;
  cursor: pointer;
  fill: var(--color-gray-900);
  padding: 8px;
}
