.title {
  font-size: 2rem;
  font-weight: 700;
  line-height: 2rem;
  color: var(--text-black);
  margin: 0 0 0 12px;
}

.card {
  padding: 10px 24px 10px 18px;
  border: 0.549518px solid var(--color-briefs-light-hex);
  box-sizing: border-box;
  box-shadow: 0px 8px 4.39614px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  margin-bottom: 20px;
  user-select: none;
}

.card[data-status="collapsed"] {
  background: white;
}

.card[data-status="expanded"] {
  background: #F4F4F4;
  
}

.titleCard {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.cardContent {
  display: flex;
  align-items: center;
}

.briefs {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 12px;
  margin-top: 26px;
  justify-content: space-between;
}

.briefItem {
  display: flex;
  width: 25%;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1.5em;
}

.button {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 1px solid var(--color-gray-50);
  background-color: transparent;
  font-size: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.flag {
  height: 20.01px;
  width: 26.67px;
  object-fit: cover;
}

.allFlag {
  height: 20px;
  width: 20px;
  object-fit: contain;
}

.selectButton {
  border: none;
  cursor: pointer;
  padding: 1.0rem 1rem;
  text-align: center;
  border: 1px solid var(--color-gray-50);
  border-radius: 2px;
  max-width: 100%;
  background-color: white;
  color: var(--text-black);
  font-size: 14px;
  line-height: 16px;
  margin-right: 1rem;
}

.saveButton {
  border: none;
  cursor: pointer;
  padding: 1rem 2rem;
  text-align: center;
  border-radius: 2px;
  max-width: 100%;
  background-color: var(--color-purple-600);
  color: var(--color-white);
  font-size: 14px;
  line-height: 16px;
}

.buttons {
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
}
