.update {
  display: flex;
  padding: 1rem 1.5rem;
  background: var(--color-white);
  box-sizing: border-box;
  border: 1px solid var(--color-gray-20);
  border-radius: 2.19807px;
  margin-bottom: -1px;
}

.title {
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  margin: 0 0 1rem;
}

.details {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.orgDetail {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: var(--color-black);
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.organizationPill {
  margin-left: 10px;
}

.organizationName {
  font-weight: bold;
}

.date {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: var(--color-gray-400);
  margin-right: 16px;
  margin-bottom: 0;
}

.education {
  margin-bottom: 0.5rem;
}

.detail {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: var(--color-black);
  margin-bottom: 0.5rem;
}

.source {
  font-weight: 400;
  font-size: 12px;
  color: var(--color-gray-400);
  margin-bottom: 0;
}

.link {
  color: var(--color-gray-400);
  text-decoration: underline;
  margin-right: 24px;
  display: inline-flex;
  align-items: center;
}

.linkIcon {
  width: 14px;
  height: 14px;
  margin-right: 8px;
}

.link:hover {
  color: var(--color-purple-600);
}

.link:hover .linkIcon {
  --color-source: var(--color-purple-600);
}