.link {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: var(--color-gray-400);
  text-decoration: none;
  margin-right: 24px;
  display: inline-flex;
  align-items: center;
}

.icon {
  width: 14px;
  height: 14px;
  margin-right: 8px;
}

.link:hover {
  color: var(--color-purple-600);
}

.link:hover .icon {
  --color-source: var(--color-purple-600);
}