.main {
  --padding-events: 24px;

  display: flex;
  flex-direction: column;
}

.body {
  --height-chart: 400px;
  background-color: var(--color-bg);
  padding-left: var(--padding-events);
  flex: 1;
  overflow-x: hidden;
  display: grid;
  grid:
    'chart chart news' var(--height-chart)
    'quotes appointments news' calc(
      100dvh - var(--height-header-filters) - var(--height-chart)
    )
    / 1fr 1fr 1fr;
  gap: 16px 24px;
}

.quotes {
  grid-area: quotes;
  padding-bottom: var(--padding-events);
}

.appointments {
  grid-area: appointments;
  padding-bottom: var(--padding-events);
}

.news {
  grid-area: news;
  border-left: var(--border-gray-1);
  background: var(--color-white);
}

/* mobile */
@media (max-width: 40rem) {
  .body {
    padding-inline: 16px;
    padding-bottom: 16px;
    grid-template:
      'chart' 300px
      'quotes' auto
      'appointments' auto
      'news' auto
      / 1fr;
    gap: 16px;

    scroll-snap-type: y proximity;
    scroll-padding-top: 16px;
    max-height: 100dvh;
  }
  .body > * > div:first-child {
    scroll-snap-align: start;
    scroll-snap-stop: always;
  }

  .quotes,
  .appointments {
    border-bottom: var(--border-gray-1);
  }

  .news {
    background: none;
    border-left: none;
  }
  /* title */
  .news > * > div:first-child {
    padding-inline: 0;
    padding-top: 0;
  }
  /* list */
  .news > * > div:last-child {
    margin-inline: -16px;
  }

}
