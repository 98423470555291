.overview {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
}

.results {
  flex-grow: 1;
}

.loading {
  flex-grow: 1;
  margin: 30px 95px 30px 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loadingSpinner {
  width: 50px;
  height: 50px;
}

.spinner {
  width: 60px;
  height: 60px;
}

.noResults {
  display: flex;
  flex: 1;
  margin: 32px 0;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.icon {
  width: 64px;
  height: 64px;
  border-radius: 12px;
  border: 1px solid var(--color-gray-25);
  display: grid;
  place-items: center;
  background-color: var(--color-white);
  margin-bottom: 1.5rem;
  box-shadow: var(--shadow-md);
}

.icon > svg {
  width: 24px;
  height: 24px;
}

.icon > svg > path {
  stroke-width: 2px;
}

.noResultsTitle {
  color: var(---color-gray-900);
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 0.5rem;
}

.noResultsSubTitle {
  color: var(--color-gray-400);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.loadingContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1;
  margin-top: 50px;
}

.entityLoadingContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1;
  margin-top: 50px;
}

.entityLoadingContainer > div {
  background-color: white;
  padding: 16px;
  border-radius: 4px;
  border: 1px solid var(--color-gray-20);
}
