.main {
  margin: 16px;
}

.label {
  color: var(--color-black);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.noteInput {
  border-radius: 6px;
  border: 1px solid var(--color-gray-75);
  background: #fff;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 8px;
}

.noteInput textarea {
  border: none;
  width: 100%;
  resize: none;
  min-height: 70px;
  margin-bottom: 10px;
}

.notes {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  gap: 12px;
}
