.main {
  padding: 16px;
  display: flex;
  flex: 1;
  width: 100%;
  background-color: var(--color-white);
  border-radius: 4px;
  border: 1px solid var(--color-gray-75);
  flex-direction: column;
  gap: 16px;

  animation: normal fadeIn 2s;
}

.title {
  color: var(--color-black);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  gap: 4px;
  margin-right: auto;
  text-wrap: nowrap;
}

.header {
  z-index: 1;
  display: flex;
  flex: 1 0 fit-content;
  flex-direction: column;
  align-items: baseline;
  gap: 16px;
}

.filters {
  display: inherit;
  flex-direction: inherit;
  gap: 8px;
  flex-wrap: wrap;
  justify-content: flex-end;
  width: 100%;
}

@media (min-width: 40rem) {
  .header {
    flex-direction: row;
  }
}

.industryFilter {
  margin-top: 0;
  z-index: 1;
  display: flex;
  flex: 1;
}

.industryFilter > div {
  margin-top: 0;
}

.countryFilter {
  margin-bottom: 0;
  display: flex;
  flex: 1;
}

.countryFilter > div {
  margin-top: 0;
}

.dateFilter {
  margin-top: 0;
  display: flex;
  flex: 1;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
