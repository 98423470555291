.main {
  cursor: pointer;
  margin: 0;
}

.right {
  border: none;
  border-top: 1px solid var(--color-gray-75);
  padding-top: 12px;
}

.info {
  font-size: 12px;
  line-height: 18px;
}
