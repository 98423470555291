.pill {
    padding: 2px 8px;
    border-radius: 40px;
    font-weight: 400;
    font-size: 11px;
    line-height: 14px;
    color: var(--color-gray-900);
    flex-shrink: 0;
}

.pill[data-type='Actor'] {
    background-color: var(--color-purple-100);
}