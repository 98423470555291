.main {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 16px;
}

.title {
  color: var(--color-black);
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  gap: 8px;
  font-family: 'HelveticaNowDisplay';
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
}

.title span {
  color: var(--color-gray-muted);
  flex: 1;
  font: inherit
}

.list {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  overflow: auto;
  scroll-snap-type: y proximity;
}

.list > * {
  scroll-snap-align: start;
}

.loadingContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.empty {
  border-radius: 4px;
  padding: 16px;
  border-radius: 4px;
  border: 1px dashed var(--color-gray-300);
  display: grid;
  place-items: center;
  height: 88px;
  color: #929aa3;
}

.seeAll {
  border: none;
  background-color: transparent;
  color: var(--color-purple-700);
  cursor: pointer;
  text-align: right;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}
