.title {
  font-size: 14px;
  line-height: 16px;
  color: var(--color-gray-100);
  text-transform: capitalize;
  margin-bottom: 0;
}


.results {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 16px;
  background-color: var(--color-white);
  border-radius: 4px;
}

.header {
  display: flex;
  align-items: center;
  gap: 8px;

  margin-bottom: 8px;
}
