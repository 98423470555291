.main {
  display: flex;
  padding: 16px;
  border-radius: 8px;
  background: var(--color-white);
  flex-direction: column;
  align-items: flex-start;
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-75);
}

.title {
  display: flex;
  flex-direction: row;
  color: var(--color-gray-800);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  align-items: center;
  margin-bottom: 8px;
  gap: 8px;
}

.icon {
  filter: brightness(0);
  user-select: none;
  margin-right: 4px;
}

.response {
  color: var(--color-gray-800);

  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.response li {
  list-style-position: inside;
  list-style-type: none;
}

.response > p {
  margin-bottom: 12px;
}

.response ol {
  margin-bottom: 8px;
}

.response ol > li {
  margin-bottom: 12px;
}

.response li p:has(strong) {
  margin-bottom: 4px;
}

.response h3 {
  color: var(--color-gray-700);
  font-weight: 600;
}