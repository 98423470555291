

.menu {
  pointer-events: none;
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
}

.menu button, .menu{
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
  font-weight: 900;
  background-repeat: no-repeat;
  background-position: center;
  font-size: 1.1em;
}


.zoomSection {
  pointer-events: all;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: flex-end;
}

.zoomSection:hover .sectionTitle {
  opacity: 1;
}

