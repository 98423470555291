.main {
  display: flex;
  width: 100%;
  max-width: 610px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 8px;
  background: var(--color-gray-75);
  position: relative;
}

.input {
  width: 100%;
  z-index: 1;
  border: none;
  max-height: 100px;
  height: auto;
  outline: none;
  background-color: transparent;
  font-size: 14px;
  resize: none; /* Prevent manual resizing */
}

.placeholder {
  position: absolute;
  display: flex;
  flex-shrink: 0;
  align-items: center;

  color: var(--Gray-Gray-400, #929aa3);

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.placeholder svg {
  margin-right: 5px;
  width: 20px;
  height: 20px;
  color: var(--color-purple-700);
}

.logo {
  width: 20px;
}
