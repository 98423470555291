.total[data-loading="true"] {
  width: 3.75rem;
  height: 2rem;
  border-radius: 0.5rem;
  margin-right: -0.5rem;
  color: var(--color-gray-25);
  background-color: var(--color-gray-25);
  animation: var(--skeleton-animation)
}

.total {
  color: var(--color-gray-400);
}