.main {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  position: relative;
  isolation: isolate;
}

.filters {
  position: fixed;
  height: 100vh;
  z-index: 3;
  background-color: var(--color-white);
  box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.12);
  width: 0;
  overflow: hidden;
}

.filters[data-open="true"] {
  width: var(--width-filters);
  overflow: visible;
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  background-color: #fafafa;
  padding: 0 60px;
}

.content[data-open="true"] {
  margin-left: var(--width-filters);
}

.content >  div {
  max-width: 1200px;
}

.header {
  position: sticky;
  z-index: 1;
  width: 100%;
  padding-top: 2rem;
  top: 0;
  background-color: #F9FAFB;
}
.header > div {
  display: flex;
  gap: 8px;
}

.title {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: var(--text-black);
  margin-bottom: 4px;
}

.results {
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-bottom: 30px;
  align-items: flex-start;
  position: relative;
}

@media screen and (max-width: 1450px) {
  .content {
    padding: 0 20px;
  }
}
