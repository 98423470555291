.extraFilter{
  background-color: white;
  margin-top: 10px;
  /* border: 1px solid var(--color-gray-25); */
  border-radius: 4px;
  display: flex;
  flex-direction: column;
}

.main {
  flex-shrink: 0;
  padding: 1px;
  user-select: none;
}

.types {
  width: 100%;
}

.collapse {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  font-size: 14px;
  line-height: 16px;
  color: var(--text-black);
  cursor: pointer;
  margin: 0px 0;
  box-sizing: border-box;
  background-color: var(--color-white);
}

.collapse:not([data-enabled='disabled']) .collapseHeader:hover {
  background-color: var(--color-gray-20);
}

.collapse[data-enabled='disabled'] {
  opacity: .7;
  cursor: not-allowed;
}

.collapse[data-status='selected']>.collapseHeader {
  background: var(--color-gray-25);
}

.collapseHeader {
  cursor: pointer;
  display: flex;
  width: 100%;
  padding: 10px;
}

.collapseHeader[data-level="1"] {
  padding-left: 20px;
}

.collapseHeader[data-level="2"] {
  padding-left: 30px;
}


.collapseContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.collapseText {
  margin: 0;
}

.collapseTotal {
  color: var(--color-gray-400);
}

.collapseIconArrow {
  width: 14px;
  height: 14px;
  margin-left: 12px;
  transform-origin: 50%;
}

.collapseIconArrow[data-status="open"] {
  transform: rotate(-90deg);
}

.collapseBody {
  width: 100%;
}

.type {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 14px;
  line-height: 16px;
  color: var(--text-black);
  cursor: pointer;
  padding: 10px;
  margin: 5px 0;
  border-radius: 2px;
}

.type[data-level="1"] {
  padding-left: 20px;
}

.type[data-level="2"] {
  padding-left: 30px;
}

.type[data-level="3"] {
  padding-left: 40px;
}

.type:not([data-enabled='disabled']):hover {
  background: rgba(228, 228, 228, 0.3);
  border-radius: 4px;
}

.type[data-enabled='disabled'] {
  opacity: .7;
  cursor: not-allowed;
}

.type[data-status='selected'] {
  background: rgba(228, 228, 228, 0.5);
  border-radius: 4px;
  color: var(--color-connected);
}

.typeName {
  text-transform: capitalize;
  margin: 0;
}

.typeTotal {
  color: var(--color-gray-400);
}

.briefs {
  margin-top: 20px;
  width: calc(100% - 10px);
}

.briefsTitle {
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: var(--text-black);
}

.subtype {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  line-height: 16px;
  color: var(--text-black);
  padding: 7px 18px 7px 20px;
}

.subtype:not([data-enabled='disabled']):hover {
  background-color: var(--color-gray-20);
}

.subtype[data-enabled='disabled'] {
  opacity: .7;
  cursor: not-allowed;
}

.subtype[data-status='selected'] {
  background: var(--color-gray-25);
}

.subtypeName {
  text-transform: capitalize;
  margin: 0;
}

.subtypeTotal {
  color: var(--color-gray-400);
}

.divider {
  width: 100%;
  height: 1px;
  background: #f3f4f6;
  margin: 16px 0;
}