.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.firstLine {
  gap: 8px;
  display: flex;
  margin-bottom: 16px;
  justify-content: space-between;
}

.firstLine h3 {
  color: var(--color-gray-700);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.firstLine aside {
  display: flex;
  gap: 8px;
  height: 20px;
}

.count {
  border-radius: 10px;
  color: var(--color-gray-700);
  background: #f3f4f6;
  padding: 0px 8px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  align-items: center;
  display: flex;
}

.closeBtn {
  display: none;
  cursor: pointer;
  justify-self: flex-end;

  border-radius: 4px;
  border: 1px solid var(--color-gray-75);
  background-color: var(--color-white);
}

.closeBtn:hover {
  filter: brightness(97%);
}

.closeBtn svg {
  width: 19px;
}

.inputContainer {
  background-color: white;
  width: 100%;
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid var(--color-gray-75);
  box-shadow: var(--shadow-light);
  display: flex;
  gap: 1.5rem;
  height: fit-content;
  margin-bottom: 16px;
}

.inputContainer svg {
  width: 16px;
}

.inputContainer input {
  border: none;
  width: 100%;
  outline: none;
  font-size: 14px;
}

.inputContainer:focus-within,
.inputContainer:hover {
  border-color: var(--color-purple-600);
}

.cleanInput {
  display: flex;
  align-items: centerđ;
}

.cleanInput > svg {
  width: 16px;
  height: 16px;
  cursor: pointer;
}


@media (min-width: 40rem) {
  .header {
    flex-direction: column;
  }
  .closeBtn {
    display: flex;
  }
}
