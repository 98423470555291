.loadingState {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1;
  border-top: 1px solid var(--color-gray-75);
}

.header {
  height: 43px;
  background-color: white;
}

.avatar {
  height: 48px;
  width: 48px;
}

.info {
  display: flex;
  width: 100%;
  flex: 1;
  gap: 16px;
}

.info div {
  width: 50%;
  height: 30px;
}
