.main {
  display: inline-flex;
  width: fit-content;
  padding: 2px 6px;
  gap: 4px;
  background-color: var(--color-cyan-50);
  color: var(--color-cyan-800);
  /* border: 1px solid var(--color-cyan-100); */

  justify-content: center;
  align-items: center;
  border-radius: 6px;

  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  margin-right: 4px;
}

.main svg {
  width: 12px;
  height: 12px;
}
