.main {
  background-color: var(--color-white);
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 4px;
  border: 1px solid var(--color-gray-75);
}

.title {
  color: var(--color-black);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  gap: 4px;
}

.entities {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 6px;
  animation: fadein 1s;
  overflow: auto;
}

.entities > div {
  width: fit-content;
}

.entity > p {
  font-size: 12px;
}

.loadingContainer {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  max-width: 300px;
  flex: 1;
}

@keyframes fadein {
  from {
    opacity: 0;
    transform: scale(0.995);
  }
  to {
    opacity: 1;
    margin-top: 0;
    transform: scale(1);
  }
}

@media (min-width: 40rem) {
  .entities {
    flex-direction: column;
    flex-wrap: nowrap;
  }
}
