.main {
  border-radius: 50px;
  background-color: var(--color-yellow-200);
  color: var(--color-yellow-800);
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-shrink: 0;

  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px;
  text-transform: lowercase;
  padding: 0 8px
}
