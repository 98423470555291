.main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-grow: 1;
  position: relative;
  margin-right: 40px;
  min-width: 400px;
  max-width: 620px;
  background-color: var(--color-white);
  height: 40px;
  border-radius: 12px;
  border: 1px solid var(--color-gray-75);
  box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.08);
}

.content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 9px 12px;
}

.icon {
  width: 16px;
  margin-right: 1rem;
}

.input {
  background-color: unset;
  border: unset;
  padding: unset;
  padding-right: 1.4rem;
  margin: unset;
  width: 100%;
  height: 100%;
  font-size: var(--font-lg);
  line-height: 1.4rem;
  outline: unset;
  border-radius: 2px;
}

.input::placeholder {
  font-size: var(--font-lg);
}

.input:focus::placeholder {
  color: var(--color-gray-100);
}

.advSearch {
  display: inline-block;
  height: 2.25rem;
  cursor: pointer;
}

.close {
  display: none;
  fill: var(--color-white);
  height: 1.6rem;
  cursor: pointer;
  margin-right: 4px;
}

.content[data-status='expanded'] .close {
  display: inline-block;
}

.content[data-status='expanded'] .advSearch {
  display: none;
}

.resultsContainer {
  background-color: var(--color-white);
  position: absolute;
  top: calc(var(--height-header) - 10px);
  width: 100%;
  box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 1rem;
  overflow-x: hidden;
}

.advancedSearch {
  padding: 10px 20px;
  font-size: 14px;
  line-height: 16px;
  color: var(--color-app-black-swamp);
  cursor: pointer;
}

.advancedSearch:hover {
  background-color: var(--color-app-gray-ede);
}

.advancedSearch:focus {
  background-color: var(--color-app-gray-ede);
  outline: none;
}

.magnifyingGlass {
  width: 12px;
  height: 12px;
  margin-right: 5px;
}

.loading {
  width: 100%;
  margin: 60px 0;
  height: 35px;
}

.total {
  margin-top: 0.5rem;
  border-top: 1px solid var(--color-app-gray-ede);
}

.totalText {
  padding: 1rem 2rem;
  color: #656A70;
  cursor: pointer;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.totalText:hover {
  background-color: var(--color-app-gray-ede);
}

.totalText:focus {
  background-color: var(--color-app-gray-ede);
  outline: none;
}

.resultsTitle {
  color: #656a70;
  font-size: 14px;
  line-height: 20px;
  padding: 1rem 2rem 0.5rem 2rem;
}
