
.label {
  font-size: 1.75rem;
  font-weight: bold;
  line-height: 2.3rem;
  color: var(--text-black);
  margin: 0 0 .4rem 0;
}

.content {
  display: flex;
  position: relative;
  width: 100%;
}

.icon {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 1.4rem;
}

.icon > svg {
  width: 2.125rem;
  height: 2.125rem;
}

.input {
  border: 1.42292px solid var(--color-gray-50);
  padding: 14px 4px 14px 40px;
  width: 100%;
  font-size: 1.5rem;
  color: var(--text-black);
  border-radius: 5px;
  box-shadow: inset 0 0 0 50px var(--color-white);
  -webkit-text-fill-color: var(--text-black);
  filter: none;
  outline-color: var(--color-purple-600);
}

.input[data-status='error'] {
  border-color: var(--color-error);
}

.input:focus {
  box-shadow: inset 0 0 0 50px var(--color-white);
}