.textValue {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-gray-900);
  margin: 4px 0;
}

.bullets {
  padding-left: 20px;
  margin-top: 8px;
}

.showToggle {
  background-color: transparent;
  border: none;
  color: var(--color-purple-600);
  cursor: pointer;
}

.showToggle:hover {
  text-decoration: underline;
}

.showToggleIcon {
  margin-left: 5px;
  width: 10px;
  height: 8px;
}

.showToggleIcon path {
  fill: var(--color-purple-600);
}
