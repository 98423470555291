.wrapper {
  display: flex;
  position: relative;
  height: 100%;
}

.container {
  display: flex;
  height: calc(100vh - var(--height-header));
  width: 100%;
  position: absolute;
  transition: 150ms;
}

.key {
  margin-left: 20px !important;
  padding-left: 16px;
}