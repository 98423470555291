.extraFilterRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  padding: 0px 10px;
}

.extraFilterRow:hover {
  background-color: var(--color-gray-10);
  cursor: pointer;
}

.extraFilterRowType {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
}

.extraFilterRowTotal {
  color: var(--color-gray-400);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  height: 38px;
}

.listSavedSearches {
  top: 0;
  left: 0;
  position: absolute;
  background-color: white;
  height: 100vh;
  width: 340px;
}

.hideSavedSearches {
  display: none;
}

.listSavedSearchesHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px 10px 20px;
  border-bottom: 1px solid #e0e0e0;
}

.listSavedSearchesHeader > span:hover {
  cursor: pointer;
}

.listSavedSearchesBody {
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
}

.listSavedSearchesBodyHeader {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
}

.searchArea {
  width: 100%;
  height: 34px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid var(--color-gray-25);
  border-radius: 4px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  padding: 0px 10px;
  margin: 10px 0px;
}

.input {
  width: 100%;
  height: 30px;
  background: var(--color-white);
  line-height: 21px;
  color: var(--text-black);
  border: none;
  outline: none;
  padding-left: 5px;
}

.input:focus-within {
  border-color: var(--color-purple-600);
}

.clear {
  cursor: pointer;
  width: 12px;
  height: 12px;
  right: 18px;
  top: 5px;
}

.clear::before,
.clear::after {
  content: '';
  transform-origin: 50%;
  position: absolute;
  width: 1px;
  background: var(--text-black);
  height: 12px;
  margin-left: 5px;
}

.clear::before {
  transform: rotate(45deg);
}

.clear::after {
  transform: rotate(-45deg);
}

.clear:hover::before,
.clear:hover::after {
  background-color: var(--color-purple-600);
}

.searchIcon {
  width: 12px;
  height: 12px;
  left: calc(-76%);
}
