.main {
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 4px;
  border: 1px solid var(--color-gray-75);
  background: var(--color-white);
  cursor: pointer;
  
}

.main h2 {
  overflow: hidden;
  color: var(--color-gray-800);
  text-overflow: ellipsis;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.main h2:hover {
  color: var(--color-purple-800);
}

.source {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #929aa3;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  cursor: pointer;
}
