.main {
  display: flex;
  height: var(--height-header-filters);
  border-bottom: var(--border-gray-1);
  border-left: none;
  border-right: none;
  background: var(--color-white);
  padding: 15px;
  justify-content: space-between;
}

.filters {
  display: flex;
  align-items: center;
}

.divider {
  width: 1px;
  height: 16px;
  margin: 0 12px;
  background-color: var(--color-gray-300);
}
