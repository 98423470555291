.main {
  padding: 24px;
  display: flex;
  flex-direction: column;
  width: 100dvw;
  height: 100dvh;
  background-color: var(--color-bg);
}

.hint {
  margin-top: 4px;
  color: #929aa3;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 16px;
}

.filters {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}

.industryFilter {
  margin-top: 0;
  flex: 1;
  z-index: 1;
  max-width: 273px;
  width: 100%;
}

.industryFilter > div {
  margin-top: 0;
}

.countryFilter {
  margin-bottom: 0;
  max-width: 273px;
  width: 100%;
}

.countryFilter > div {
  margin-top: 0;
}

.label {
  color: var(--color-gray-800);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-top: 8px;
  margin-bottom: 4px;
}

.textarea {
  padding: 8px;
  resize: none;
  border-radius: 4px;
  height: 200px;
  background-color: white;
  border: 1px solid var(--color-gray-25);
  width: 100%;
  font-size: 14px;
}

.input {
  border-radius: 4px;
  padding: 10px;
  font-size: 14px;
  margin-bottom: 4px;
  width: 505px;
}

.textarea::placeholder {
  color: #929aa3;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.input > div > input {
  font-size: 14px;
}

.input > div > input::placeholder {
  color: #929aa3;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.interest {
  display: flex;
  align-items: center;
  gap: 10px;
}

.input:focus-within,
.textarea:focus {
  border: 1px solid var(--color-purple-600) !important;
}

.actions {
  display: flex;
  gap: 12px;
  /* flex: 1 1; */
  margin-top: 16px;
}

.actions > button {
  display: flex;
  flex: 1;
}

.trash {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.error {
  color: #dc3023;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  margin-top: 8px;
}

.loadingState {
  display: flex;
  gap: 16px;
  flex-direction: column;
}

@media (min-width: 40rem) {
  .main {
    width: auto;
    height: auto;
  }
}
