.setupTitle {
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  text-align: center;
}

.main {
  padding: 16px;
  display: flex;
  flex: 1;
  width: 100%;
  background-color: var(--color-white);
  border-radius: 4px;
  border: 1px solid var(--color-gray-75);
  flex-direction: column;
  gap: 16px;

  align-items: center;
  justify-content: center;
}


.content {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.content h3 {
  color: var(--color-gray-100);
}
