.button {
  background: white;
  border: 1px solid var(--color-gray-50);
  border-radius: 2px;
  padding: 4px 5px;
  color: var(--color-gray-700);
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.button[data-type='icon-button'] {
  padding: 7px;
}

.button:disabled {
  border: 1px solid var(--color-gray-50);
  color: var(--color-gray-50);
  cursor: not-allowed;
  pointer-events: none;
}

.button:hover {
  border: 1px solid var(--color-purple-600);
  color: var(--color-purple-600);
}

.button:focus:not(:active) {
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px var(--color-gray-25);
}

.button:active {
  border: 1px solid var(--color-gray-800);
  color: var(--color-gray-800);
}

.icon {
  font-size: 0;
}

.button[data-type='text-button'] .icon[data-position="leading"] {
  padding-right: 8px;
}

.button[data-type='text-button'] .icon[data-position="trailing"] {
  padding-left: 8px;
}