.tooltip {
  z-index: 10000;
}

.tooltip * {
  font-size: 12px;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 18px;

}

.wrapper {
  display: inline;
}

.text {
  text-align: justify;
  white-space: pre-wrap;
  margin: 0;

  box-shadow: 0px 12px 16px -4px rgba(37, 37, 37, 0.08), 0px 4px 6px -2px rgba(37, 37, 37, 0.06);
  border-radius: 0.25rem;
  padding: 8px 12px;

  max-width: 500px;
  border-radius: 8px;
}

.text[data-theme="dark"] {
  background-color: #13191F;
  color: var(--color-white);
}

.text[data-theme="light"] {
  background-color: #F9FAFB;
  color: var(--color-gray-800);
}
