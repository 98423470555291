.header {
  position: relative;
  padding-top: 48px;
}

.header[data-sticky='true'] {
  padding-top: 0;
}

.controls {
  z-index: 4;
  position: absolute;
  top: 0;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  background-color: white;
}

.controls[data-border='true'] {
  border-bottom: 1px solid var(--color-gray-75);
}

.controls[data-sticky='true'] {
  position: sticky;
  top: 0;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.spinner {
  width: 60px;
}

.goBack {
  border-radius: 4px;
  border: 1px solid var(--color-gray-75);
  background: var(--color-white);
  color: var(--color-gray-600);
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 4px 8px;
  position: absolute;
  left: 55px;
  top: 8px;
  display: flex;
  gap: 4px;
  cursor: pointer;
}

.goBack:hover {
  filter: brightness(0.96);
}
