.main {
  display: flex;
  gap: 4px;
  color: var(--color-gray-600);
  text-align: center;
  font-variant-numeric: lining-nums tabular-nums;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  padding: 2px 4px 2px 8px;
  justify-content: flex-end;
  align-items: center;
  border-radius: 16px;
  text-wrap: nowrap;
}

.main[data-impact='Positive'] {
  background-color: #d1fadf;
}

.main[data-impact='Neutral'] {
  background-color: #f3f4f6;
}

.main[data-impact='Negative'] {
  background-color: #fef3f2;
}

.icon {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon[data-impact='Positive'] {
  background-color: #12B76A;
}

.icon[data-impact='Neutral'] {
  background-color: #929AA3;
}

.icon[data-impact='Negative'] {
  background-color: #F04438;
}
