.subheader {
  padding: 2px 12px;
	min-height: 32px;
  color: #929aa3;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 166.667% */
  letter-spacing: 1.08px;
  text-transform: uppercase;

  background-color: #f3f4f6;

  display: flex;
	align-items: center;
  justify-content: space-between;

  z-index: 1;
  position: sticky;
  top: 0;
}

.button {
	color: var(--color-purple-700);
	text-align: center;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px; /* 142.857% */
	border: none;
	background-color: transparent;
	cursor: pointer;
}
