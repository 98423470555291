.online {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
}

.onlineLink {
  padding: 4px;
}

.onlineLink a {
  display: flex;
  height: fit-content;
}

.onlineLink svg {
  width: 16px;
  height: 16px;
  fill: white;
  opacity: 0.7;
}