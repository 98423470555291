.button {
  border: none;
  outline: none;
  margin: 0;
  height: fit-content;
  background-color: var(--color-white);
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.button[data-size='small'] {
  width: 20px;
  height: 20px;
}

.button[data-size='medium'] {
  width: 24px;
  height: 24px;
}

.button[data-size='small'] svg {
  width: 12px;
  height: 12px;
}

.button[data-size='medium'] svg {
  width: 16px;
  height: 16px;
}

.button:hover {
  filter: brightness(95%);
}
