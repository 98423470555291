.card {
  display: flex;
  flex-direction: column;

  border-radius: 4px;
  border: 1px solid #E5E7EB;
  background: var(--color-white);

  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.cardContent {
  padding: 6px 12px;

  color: var(--color-gray-800);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
}

.cardTitle {
  color: var(--color-gray-950);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 4px;
}

.totalPages {
  color: #929aa3;

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.buttons {
  display: flex;
  gap: 4px;
  align-items: center;
}

.navigationButton {
  border-radius: 4px;
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-white);
  border: 1px solid var(--color-gray-75);
  color: var(--color-gray-400);
  cursor: pointer;
}

.navigationButton[data-disabled=true] {
  pointer-events: none;
  opacity: 0.4;
}

.navigationButton:hover {
  filter: brightness(95%);
}

.pageInfo {
  color: var(--color-gray-100);

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}


.itemTitle {
  color: var(--color-gray-500);

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.itemContent {
  color: var(--color-gray-800);

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
