.card {
  display: flex;
  max-width: 1200px;
  gap: 1px;
  border-radius: 4px;
  background-color: var(--color-white);
  margin-bottom: 8px;
  flex-direction: column;

  container-name: myComponent;
  container-type: inline-size;
}

.row {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.leftColumn {
  padding: 16px;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.rightColumn {
  padding: 16px;
  border: none;
  border-top: 1px solid var(--color-gray-75);
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 10px;
  max-width: 480px;
}

@media (min-width: 40rem) {
  .row {
    flex-direction: row;
  }
  .rightColumn {
    border: none;
    border-left: 1px solid var(--color-gray-75);
  }
}

.dateRow {
  color: #929aa3;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 4px;

  display: flex;
  align-items: center;
  gap: 4px;
}

.title {
  color: var(--color-gray-800);
  font-family: 'HelveticaNowDisplay';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0%;
}

.title[data-click='true']:hover {
  color: var(--color-purple-800);
  cursor: pointer;
}

.infoTitle {
  display: flex;
  flex-direction: row;

  color: var(--color-black);
  font-weight: 500;
}

.infoChildren {
  display: flex;
  flex-wrap: wrap;

  color: var(--color-gray-700);
  font-weight: 400;
}

.infoColumn {
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
}

.bottomRow {
  border-top: 1px solid var(--color-gray-75);
  padding: 16px;
}

.sources {
  display: flex;
  flex-direction: row;
  gap: 4px;
}

.pills {
  display: flex;
  flex-wrap: wrap;
}

.regulation {
  overflow: hidden;
  color: #000;
  text-overflow: ellipsis;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-top: 6px;
  list-style: inside;
}

.bullets {
  margin-top: 8px;
  list-style-position: outside;
  padding-left: 20px;
}

.bullets li {
  margin-bottom: 4px;
}

.summary > div {
  margin-right: 4px;
}
