.main {
  padding: 16px;
  border-radius: 8px;
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-75);
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.main h2 {
  color: var(--color-gray-800);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
}

.tagList {
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
}

.itemHeader {
  display: flex;
  align-items: center;
  gap: 4px;
  color: var(--color-gray-600);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 4px;
}

.eventList {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

.firstContainer {
  display: grid;
  gap: 2rem;
  grid-template-columns: 1fr 1fr;
}

.seeGraph{
  right: 0;
  top: 10px;
  padding: 6px 10px;
  margin-right: 10px;
  border: var(--border-gray-1);
  border-radius: 4px;
  background: #FDFDFF;
}

.networkIcon {
  width: 18px;
  height: 12px;
  position: relative;
  border: none;
  margin-right: 1px;
}

.semanticSearch{
  color: black;
}

.sourcesHeader{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.seeGraph:hover  {
  background: #F2F2F2;
  cursor: pointer;
}