.button {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 1px solid var(--color-gray-50);
  background-color: transparent;
  font-size: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.button:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.button:disabled {
  opacity: .4;
  pointer-events: none;
}

.button[data-direction='left'] {
  margin-right: 4px;
}

.button[data-direction='right'] {
  margin-left: 4px;
}

.icon {
  width: 6px;
  height: 8px;
}