.main {
  gap: 2rem;
  margin: 32px auto; 
  padding: 0 32px;
  display: flex;
  max-width: 1030px;
  flex-direction: column;
}

.divider {
  width: 100%;
  height: 1px;
  margin: 3rem 0 2rem 0;
  background-color: var(--color-gray-75);
}
