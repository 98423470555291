.main {
  width: 52px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 2.667px solid var(--color-white);
  box-shadow: 0px 2.667px 4px -1.333px rgba(16, 24, 40, 0.03),
    0px 8px 10.667px -2.667px rgba(16, 24, 40, 0.08);
}

.main[data-small=true] {
  width: 28px;
  height: 28px;
}

.main[data-small=true] img {
  width: 24px;
  height: 24px;
}