.loadingScreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  animation: loadingScreenFadeOut 2s ease-out 8s forwards;
}

.logo {
  width: 40px;
  height: 40px;
}
