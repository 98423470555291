@font-face {
  font-family: 'HelveticaNowText';
  src: url('./fonts/HelveticaNowText-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNowText';
  src: url('./fonts/HelveticaNowText-It.otf') format('opentype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'HelveticaNowText';
  src: url('./fonts/HelveticaNowText-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNowText';
  src: url('./fonts/HelveticaNowText.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNowDisplay';
  src: url('./fonts/HelveticaNowDisplay-Bold.woff2') format('woff2'),
    url('./fonts/HelveticaNowDisplay-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNowDisplay';
  src: url('./fonts/HelveticaNowDisplay-Md.woff2') format('woff2'),
    url('./fonts/HelveticaNowDisplay-Md.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
