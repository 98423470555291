.main {
  width: 100%;
  padding: 16px 20px;
}

.loadingState {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.list {
  display: flex;
  flex-direction: column;
  overflow: scroll;
  margin-left: -20px;
  width: calc(100% + 40px);
}

.markAllRead {
  color: var(--color-purple-600);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  cursor: pointer;
}

.markAllRead:hover {
  color: var(--color-purple-700);
}

.empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
}

.emptyIcon {
  display: flex;
  width: 44px;
  height: 44px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 6px;
  margin-bottom: 16px;
  background: var(--color-gray-75);
}

.emptyTitle {
  color: #13191f;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 4px;
}

.emptyDescription {
  color: var(--color-gray-600);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
}

.timeline {
  color: var(--color-gray-500);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px; /* 141.667% */
  margin-left: 20px;
  margin-bottom: 6px;
  margin-top: 12px;
}
