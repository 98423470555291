.main {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 400px;
}

.header {
  display: flex;
  justify-content: space-between;
}

.header h2 {
  color: #101828;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.closeBtn {
  height: fit-content;
  border-radius: 50%;
  display: flex;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: var(--color-white);
}

.closeBtn:hover {
  filter: brightness(95%);
}

.closeBtn svg {
  width: 24px;
  height: 24px;
}

.actions {
  display: flex;
  gap: 12px;
  flex: 1;
}

.actions button {
  flex: 1;
}

.message {
  color: #404040;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  flex-direction: column;
}

.message > div {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 0;
}


.textarea {
  padding: 8px;
  resize: none;
  border-radius: 4px;
  height: 200px;
  background-color: white;
  opacity: 0.8;
  border: 1px solid var(--color-gray-100);
}