

.item {
  padding: 0.25rem 2rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.item:hover {
  background-color: var(--color-app-gray-ede);
}

.item:focus {
  background-color: var(--color-app-gray-ede);
  outline: none;
}

.item > .pill {
  cursor: pointer;
}

.name {
  font-size: 14px;
  line-height: 16px;
  color: var(--color-app-black-swamp);
}