.main {
  display: flex;
  gap:16px;
  flex-direction: column;
  padding: 16px;
}

.actions {
  display: flex;
  gap: 8px;
}

.actions > button {
  flex: 1;
}

.bioTitle {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.bioContent {
  color: #2a2e31;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-block: 8px;

  display: -webkit-box;
  -webkit-line-clamp: 3; /* Show only 3 lines */
  line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

ul.bioContent li {
  list-style-type: disc;
  margin-left: 1em;
  margin-bottom: 0.5em;
}

.bioContent[data-expanded='true'] {
  -webkit-line-clamp: unset;
  line-clamp: unset;
}

.showMore {
  border: none;
  background: none;
  color: #929aa3;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0px;
}

.showMore[data-expanded='true'] svg {
  transform: rotate(180deg);
}

.showMore:hover {
  color: #737981;
}

.chevronDown path {
  fill: #929aa3;
}

.cards {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.outsideSubscription {
  font-size: 2rem;
  font-weight: bold;
  line-height: 2.5rem;
  margin-left: 3.5rem;
  margin-right: 3.5rem;
  margin-bottom: 1rem;
}
