.help {
  font-size: 1.75rem;
  font-weight: 500;
  line-height: 2.3rem;
  color: var(--color-gray-50);
  margin: 0;
}

.link {
  color: var(--color-purple-600);
}

.link:hover {
  color: var(--color-purple-600);
}