.card {
  padding: 16px;
  display: flex;
  flex: 1;
  width: 100%;
  background-color: var(--color-white);
  border-radius: 4px;
  border: 1px solid var(--color-gray-75);
  flex-direction: column;
  gap: 16px;
}

.title {
  color: var(--color-black);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  align-items: baseline;
  gap: 4px 8px;
  flex-wrap: wrap-reverse;
  flex: 1
}

.title span {
  color: #929aa3;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.content {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 8px;
  animation: fadein 1s;
  justify-content: space-between;
}

.content h2 {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  column-count: 2;
  column-gap: 12px;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: last baseline;
}

.seeFull {
  background-color: white;
  border: none;
  cursor: pointer;

  color: var(--color-purple-700);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

@keyframes fadein {
  from {
    opacity: 0;
    transform: scale(0.995);
  }
  to {
    opacity: 1;
    margin-top: 0;
    transform: scale(1);
  }
}
