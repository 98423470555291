.main {
  display: flex;
  flex-direction: column;
}

.list {
  display: flex;
  flex-direction: column;
}

@media (min-width: 40rem) {
  .list {
    max-height: var(--height-filter-body);
  }
}
