.request {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 150px;
  padding-bottom: 30px;
}

.text {
  font-size: 14px;
  line-height: 16px;
  color: var(--color-black);
  margin-bottom: 15px;
}