.card {
  padding: 10px;
  border-radius: 8px;
  border: 1px solid var(--color-gray-75);
  background: var(--color-white);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  transition: filter 0.2s;
  white-space: wrap;
  display: flex;
  flex-direction: column;
}

.card:hover {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
}

.card:hover {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
}

.card[data-selected='true'] {
  border-color: var(--color-purple-600);
}

.card[data-selected='true'] button[data-type="iconButton"] {
  background-color: #f9fafb;
}