.main {
  background-color: white;
  width: 100%;
  padding: 2rem;
  border-radius: 12px;
  border: 1px solid var(--color-gray-75);
  box-shadow: var(--shadow-light);
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  height: fit-content;
}

.input {
  margin: none;
  padding: none;
  border: none;
  outline: none;
  font-size: 2rem;
  flex: 1;
  flex-basis: 340px;
}

.icon {
  width: 18px;
}

.inputContainer {
  display: flex;
  gap: 1.5rem;
  flex-wrap: wrap;
}
