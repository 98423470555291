.main {
  background-color: var(--color-gray-25);
  animation: skeleton 1s alternate infinite;
  border-radius: 2rem;
}

@keyframes skeleton {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.4;
  }
}
