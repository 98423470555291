.container {
  display: flex;
  padding: 1rem 2rem;
  flex-direction: column;
}

/* Container */
.container::-webkit-scrollbar {
  width: 0.75rem;
  display: block;
}

/* Track */
.container::-webkit-scrollbar-track {
  background: var(--color-white);
}

/* Handle */
.container::-webkit-scrollbar-thumb {
  background: var(--color-gray-20);
  border-radius: 50px;
}

/* Handle on hover */
.container::-webkit-scrollbar-thumb:hover {
  background: var(--color-gray-25);
}

.optionRow {
  display: flex;
  font-size: 14px;
  flex-direction: row;
  align-items: center;
}

.chevron {
  width: 22px;
  height: 22px;
  cursor: pointer;
  visibility: hidden;
  transform: rotate(-90deg);
  animation: transform 0.5s;
}

.chevron[data-hide-svg='true'] {
  display: none;
}

.chevron[data-visible='true'] {
  visibility: visible;
}

.chevron[data-expanded='true'] {
  transform: rotate(0);
}

.chevron > path {
  fill: var(--color-gray-200);
}

.chevron:hover > path {
  fill: var(--color-gray-400);
}

.option:hover {
  background-color: #f3f4f6;
}

.option {
  flex: 1;
  display: flex;
  padding: 1.1rem;
  cursor: pointer;
  align-items: center;
  flex-direction: row;
  border-radius: 0.5rem;
  font-weight: 500;
}

.option > input {
  cursor: pointer;
  margin-right: 1rem;
  pointer-events: none;
}
