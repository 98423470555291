.main {
  color: var(--color-gray-800);
  cursor: pointer;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  flex-direction: column;
}

.main:hover {
  background-color: var(--color-gray-10);
}

.main[data-selected='true'] {
  background-color: #f3f4f6;
  border-radius: 6px;
  outline: 1px solid var(--color-purple-600);
}

.main[data-expanded='true'] .content:last-child {
  padding-bottom: 10px;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 16px 10px 16px;
}

.header[data-selected='true'] {
  font-weight: 500;
}

.count {
  color: var(--color-gray-500);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.content {
  opacity: 0;
  padding: 0 8px;
  overflow: hidden;
  max-height: 0;
  transition: opacity 0.3s ease-in-out;
  /* transition: max-height 0.3s ease-out; */
}

.content[data-selected='true'] {
  max-height: 500px;
  opacity: 1;
}
