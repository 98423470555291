.searchCard {
  padding: 10px;
  border-radius: 8px;
  border: 1px solid var(--color-gray-75);
  background: var(--color-white);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  transition: filter 0.2s;
  white-space: wrap;
}

.searchCard:hover {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
}

.searchCardHeader {
  display: flex;
}

.searchCard[data-active='true'] {
  border-color: var(--color-purple-600);
}

.tagList {
  display: flex;
  flex-wrap: wrap;
  margin-top: 8px;
}

.searchText {
  display: flex;
  align-items: center;
  line-height: 14px;
  font-size: 14px;
}

.moreBtn {
  border: none;
  outline: none;
  margin: 0;
  height: fit-content;
  background-color: var(--color-white);
  width: 20px;
  height: 20px;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.moreBtn svg {
  width: 12px;
  height: 12px;
}

.moreBtn:hover {
  filter: brightness(95%);
}

.menu {
  display: flex;
  background: var(--color-white);
  border: 0.549518px solid #efefef;
  box-sizing: border-box;
  box-shadow: 0px 2.19807px 4.39614px rgba(0, 0, 0, 0.06);
  border-radius: 2.19807px;
  margin: 16px;
  flex-direction: column;
  position: relative;
  font-size: 14px;
  z-index: 200;
}

.menuItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 18px 10px 18px;
  border-bottom: 1px solid #efefef;
  gap: 8px;
  background-color: var(--color-white);
}

.menuItem:hover {
  cursor: pointer;
  filter: brightness(98%);
}

.menuItem[data-removal='true'] {
  color: red;
}

.editIcon {
  width: 18px;
  height: 12px;
  position: relative;
  border: none;
  opacity: 0.5;
}

.homeIcon {
  width: 18px;
  height: 12px;
  position: relative;
  border: none;
  opacity: 0.5;
}

.trashIcon {
  width: 20px;
  height: 16px;
  position: relative;
  border: none;
}

.homeFeed {
  margin-bottom: 10px;
  padding: 5px 2px 5px 6px;
  border: 1px solid #b2ddff;
  font-size: 12px;
  border-radius: 4px;
  width: 77px;
  background-color: #eff8ff;
  color: #175cd3;
}

.searchIcon {
  width: 12px;
  margin-right: 6px;
}

.searchName {
  display: flex;
  flex: 1;

  color: var(--color-gray-800);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
