.optionRow {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;

  color: #13191f;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 8px 8px 8px 0;
  background-color: #f3f4f6;
  text-align: left;
}

.optionRow:hover {
  filter: brightness(98%);
}

.input {
  cursor: pointer;
  width: 14px;
  height: 14px;
  margin-top: 3px;
  margin-left: 8px;
}

.name {
  display: flex;
  flex: 1;
}
