.customBody {
  height: fit-content;
}

.customFooter {
  display: flex;
  justify-content: space-between;
}

.customFooter button,
.customFooter > div > button {
  display: flex;
  flex-grow: 0;
  flex-basis: 130px;
  text-wrap: nowrap;
}

.dateRange {
  max-height: var(--height-filter-body);
  background-color: var(--color-white);
  border-radius: 4px;
  flex-direction: column;
}

.dateRange :global(.rdrDefinedRangesWrapper) {
  width: 100%;
  border: none;
  border-bottom: 1px solid #eff2f7;
}

.dateRange :global(.rdrDateDisplayWrapper),
.dateRange :global(.rdrInputRanges) {
  display: none;
}

.dateRange :global(.rdrCalendarWrapper) {
  border-radius: 4px;
}

.dateRange :global(.rdrDateDisplayItem) {
  box-shadow: none;
}

.dateRange :global(.rdrStaticRanges) {
  padding-top: 10px;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 2px;
}

.dateRange :global(.rdrMonthsVertical) {
  overflow: auto;
  align-items: center;
}

.dateRange :global(.rdrStartEdge) {
  background-color: var(--color-purple-700);
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.dateRange :global(.rdrEndEdge) {
  background-color: var(--color-purple-700);
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.dateRange :global(.rdrInRange) {
  background-color: var(--color-purple-50);
}

.dateRange :global(.rdrMonth) {
  width: 22.667em;
}

.dateRange :global(.rdrStaticRange) {
  border: none;
  padding: 4px 4px;
}

@media (min-width: 40rem) {
  .dateRange {
    max-height: var(--height-filter-body);
    flex-direction: row;
  }
  .dateRange :global(.rdrStaticRanges) {
    flex-direction: column;
  }
  .dateRange :global(.rdrStaticRange) {
    padding: 4px 16px;
  }
  .dateRange :global(.rdrDefinedRangesWrapper) {
    display: block;
    border-radius: 4px;
    max-width: 150px;
    width: fit-content;
    height: fit-content;
    border: none;
    border-right: 1px solid #eff2f7;
  }
  .dateRange :global(.rdrStaticRange .rdrStaticRangeLabel) {
    padding: 8px 16px;
  }
}

.dateRange :global(.rdrStaticRange .rdrStaticRangeLabel) {
  border: none;
  padding: 6px 8px;
  border-radius: 6px;
  text-wrap: nowrap;
}
.dateRange :global(.rdrStaticRange:hover .rdrStaticRangeLabel),
.dateRange :global(.rdrStaticRange:focus .rdrStaticRangeLabel) {
  background-color: var(--color-gray-20);
  cursor: pointer;
}

.dateRange
  :global(.rdrStaticRange.rdrStaticRangeSelected .rdrStaticRangeLabel) {
  background-color: var(--color-gray-20);
  color: var(--color-purple-700);
  font-weight: 500;
}

.dateRange :global(.rdrDateInput) {
  border: 1px solid var(--color-gray-50);
}

.dateRange :global(.rdrNextPrevButton) {
  background-color: var(--color-white);
  cursor: pointer;
}

.dateRange :global(.rdrNextPrevButton:hover) {
  background-color: var(--color-gray-20);
  cursor: pointer;
}

.dateRange
  :global(.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span),
.dateRange
  :global(.rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span) {
  color: var(--color-gray-700);
}

.dateRange
  :global(.rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span),
.dateRange
  :global(.rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span) {
  color: white;
}

.dateRange :global(.rdrDayToday .rdrDayNumber span:after),
.dateRange
  :global(.rdrDayToday:not(.rdrDayPassive)
    .rdrInRange
    ~ .rdrDayNumber
    span:after) {
  bottom: 2px;
  width: 4px;
  height: 4px;
  background-color: var(--color-gray-700);
}
