.labelContainer {
  display: flex;
  justify-content: space-between;
}

.label {
  font-size: 1.75rem;
  font-weight: bold;
  line-height: 2.3rem;
  color: var(--text-black);
  margin: 0 0 .4rem 0;
}

.forgotPassword {
  color: var(--color-purple-600);
  font-size: 1.75rem;
  font-weight: 500;
  line-height: 2.3rem;
}

.inputContent {
  display: flex;
  position: relative;
  width: 100%;
}

.icon[data-position='left'] {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 1.4rem;
}

.icon[data-position='left'] > svg {
  width: 2.125rem;
  height: 2.125rem;
}

.icon[data-position='right'] {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  margin-right: .8rem;
}

.icon[data-position='right']:hover {
  cursor: pointer;
}

.input {
  border: 1.42292px solid var(--color-gray-50);
  padding: 14px 4px 14px 40px;
  width: 100%;
  font-size: 1.5rem;
  color: var(--text-black);
  border-radius: 5px;
  box-shadow: inset 0 0 0 50px var(--color-white);
  -webkit-text-fill-color: var(--text-black);
  filter: none;
  outline-color: var(--color-purple-600);
}

.input:focus {
  box-shadow: inset 0 0 0 50px var(--color-white);
}

.input[data-status='error'] {
  border-color: var(--color-error);
}