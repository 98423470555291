.main {
  width: 100%;
  padding: 16px 20px;
}

.loadingState {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.list {
  gap: 16px;
  display: flex;
  flex-direction: column;
  overflow: scroll;
}
