.network {
  display: flex;
  margin-left: 1.5rem;
  margin-bottom: 2.4rem;
}

.key {
  transition: opacity 0.3s ease-in-out;
  min-width: 100px;
  position: absolute;
  align-self: flex-end;
  user-select: none;
  left: 10px;
}

.key .faded {
  opacity: .4;
}

.key .keyItem {
  cursor: pointer;
}

.controls {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
}

.controls[data-area='brief'] {
  padding-bottom: 30px;
}

.controls input, .controls .controlInput {
  box-sizing: border-box;
  width: 32px;
  border: 1px solid #efefef;
  height: 32px;
  border-radius: 50%;
  color: #808285;
  background: #efefef;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 8px 2px 0 0;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
  font-weight: 900;
  background-repeat: no-repeat;
  background-position: center;
  font-size: 1.1em;
}

.controls input:active, .controls .controlInput:active {
  transform: scale(1.3) perspective(1px);
  transition: transform 0.1s;
}

.controls .zoomInc {
  background-image: url("./resources/graph-plus.svg");
}

.controls .zoomDec {
  background-image: url("./resources/graph-minus.svg");
}

.controls .zoomToFit {
  background-image: url("./resources/graph-expand.svg");
}

.topControls {
  pointer-events: all;
  display: flex;
  flex-direction: row;
  margin-right: 2rem;
  align-items: center;
}

.topControls input {
  margin-left: 10px;
}

.bottomControls {
  display: flex;
  flex-direction: column;
}

.zoomSection {
  pointer-events: all;
  display: flex;
  flex-direction: column;
  padding-right: 5px;
  margin-bottom: 3.125rem;
  align-items: center;
  align-self: flex-end;
}

.zoomSection:hover .sectionTitle {
  opacity: 1;
}

.labelsSection {
  pointer-events: all;
  margin-right: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sectionTitle {
  opacity: 0;
  color: #707876;
  font-weight: 500;
  font-size: 1.5em;
  line-height: 22px;
  margin-bottom: 0;
  text-transform: uppercase;
  transition: opacity .5s;
}
