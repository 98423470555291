.innerBox {
  border: 1px solid var(--color-gray-25);
  padding: 5px;
}

.paramTitle {
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  color: #6D7176;
}

.paramContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.closeFreeText {
  opacity: .4;
  width: 20px;
  height: 25px;
  margin-left: 8px;
  padding-top: 10px;
}

.closeFreeText:hover {
  cursor: pointer;
  opacity: .8;
}

.closeType {
  width: 15px;
  height: 15px;
  margin-left: 8px;
}

.typePill {
  background-color: var(--color-purple-200);
  padding: 5px 8px;
  border-radius: 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  display: flex;
}

.typePill:hover {
  cursor: pointer;
}

.saveSearchModalBody {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.nameInput {
  border: 1px solid var(--color-gray-50);
  border-radius: 2px;
  padding: 8px 16px;
  margin-bottom: 10px;
  height: 40px;
}

.errorNameInput {
  border: 1px solid var(--color-error-500);
  border-radius: 2px;
  padding: 8px 16px;
  margin-bottom: 10px;
  height: 40px;
}

.searchDetails {
  display: flex;
  flex-direction: column;
  padding: 0 0 15px 0;
  flex-wrap: wrap;
}

.textSearch {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  padding: 0px 5px;
  color: #414141;
}

.errorMsg {
  font-size: 12px;
  color: var(--color-error-500);
}

.closeIcon {
  height: 24px;
  width: 24px;
}

.saveSearchModal {
  display: flex;
  flex-direction: column;
}

.saveSearchModal>h1 {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #101828;
}

.saveSearchModalHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.saveSearchModalHeader>button {
  border: none;
  background-color: transparent;
}

.saveSearchModalHeader>button:hover {
  cursor: pointer;
}

.saveSearchModalFooter {
  border-top: 1px solid var(--color-gray-25);
  padding: 20px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.cancelButton {
  background: var(--color-white);
  border: 1px solid var(--color-gray-50);
  border-radius: 2px;
  padding: 8px 16px;
  width: 100%;
}

.cancelButton:hover, .saveButton:hover {
  cursor: pointer;
  filter: brightness(90%);
}

.saveButton {
  background-color: var(--color-purple-600);
  margin-left: 10px;
  border: 1px solid var(--color-gray-50);
  border-radius: 2px;
  padding: 8px 16px;
  width: 100%;
  color: var(--color-white);
}

.saveButton[data-update-type="removeHomeFeed"] {
  background-color: var(--color-error-500);
}

.message {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--color-gray-100);
}