.content {
  grid-area: content;
  height: inherit;
  max-width: 100dvw;
  isolation: isolate;
}

.dossierContainer {
  grid-area: dossier;
  position: fixed;
  top: 0;
  right: 0;
  width: 0;
  height: 100dvh;
  background: var(--color-white);
  transition: width 300ms;
  isolation: isolate;
}
.dossierContainer[data-active='true']:has(.dossier[data-status='open']) {
  width: 100dvw;
  transition: width 300ms;
}

.dossier[data-status='open'] {
  height: inherit;
  width: inherit;
  overflow-y: auto;
}

/* desktop */
@media (min-width: 40rem) {
  .dossierContainer[data-active='true']:has(.dossier[data-status='open']) {
    width: var(--width-dossier);
  }
  .dossierContainer:has(.dossier[data-status='open']) {
    position: sticky;
    width: var(--width-dossier);
    border-left: var(--border-gray-1);
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.14);
  }
}
