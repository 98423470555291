.main {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 0;
  font-weight: 500;
  font-size: 14px;
}

.titleHighlight {
  color: var(--color-gray-700);
  background-color: var(--color-gray-75);
  padding: 2px 5px;
  font-weight: bold;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  border-radius: 0.5rem;
}

.titleFilters {
  position: relative;
}

.titleFilters:hover>.titleFiltersPopover {
  display: flex;
}

.titleFiltersPopover {
  display: none;
  position: absolute;
  top: calc(100% + 5px);
  left: -10px;
  flex-direction: column;
  align-items: flex-start;
  white-space: nowrap;
  box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  background-color: white;
  padding: 5px 10px;
  z-index: 2;
}

.titlePopoverHighlight {
  margin: 5px;
  text-transform: capitalize;
}

.savedSearch {
  border: 1px solid var(--color-gray-25);
  border-radius: 4px;
  padding: 5px 10px;
  margin: 10px 0px;
}

.savedSearchIcon {
  height: 12px;
  width: 12px;
  position: relative;
  border: none;
  margin-right: 5px;
  opacity: .5;
}

.summary {
  flex: 1;
  line-height: 200%;
}

.saveSearchContainer {
  background: #FDFDFF;
}

.setAsHomeFeed {
  right: 0;
  top: 10px;
  padding: 6px 10px;
  margin-right: 10px;
  border: var(--border-gray-1);
  border-radius: 4px;
  background: #FDFDFF;
}

.backButton {
  right: 0;
  top: 10px;
  padding: 6px 10px;
  margin-right: 10px;
  border: var(--border-gray-1);
  border-radius: 4px;
  background: #FDFDFF;
}

.backButton:hover, .setAsHomeFeed:hover {
  background: #F2F2F2;
  cursor: pointer;
}

.homeFeed[data-feed-action="remove"] {
  color: #cc0909
}
