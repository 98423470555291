.compliant {
  display: flex;
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 2px 8px 2px 3px;
  color: var(--color-green-700);
  background-color: var(--color-green-25);

  cursor: pointer;
  border-radius: 4px;
  border: 1px solid var(--color-green-700);

  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}