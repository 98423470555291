.main {
  display: flex;
  flex-direction: column;
  overflow: scroll;
}

.header {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: white;
}

.divider {
  width: 100%;
  height: 1px;
  flex-shrink: 0;
  background-color: var(--color-gray-75);
}

.divider:nth-child(3) {
  width: calc(100% - 32px);
  margin-left: 16px;
  margin-top: 4px;
}

.content {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.icon {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-purple-50);
}

.icon svg {
  width: 20px;
  height: 20px;
}

.icon path {
  fill: var(--color-purple-600);
}

.name {
  color: var(--color-graay-800);

  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}

.headline {
  color: var(--color-gray-800);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}


/* br score from eventdossier */
.item {
  display: flex;
  flex-direction: column;
}

.itemTitle {
  color: #656a70;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.itemContent {
  color: var(--color-graay-800);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  display: flex;
}
