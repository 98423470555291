.main {
  display: flex;
  flex-direction: column;
  overflow: scroll;
}

.header {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: white;
}

.divider {
  width: 100%;
  height: 1px;
  flex-shrink: 0;
  background-color: var(--color-gray-75);
}

.content {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.typeDate {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 12px;
}

.typeDate[data-expanded='true'] {
  gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
}

.icon {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-purple-50);
}

.icon path {
  fill: var(--color-purple-600);
}

.name {
  color: var(--color-graay-800);

  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}

.item {
  display: flex;
  flex-direction: column;
}

.itemTitle {
  color: #656a70;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.itemContent {
  color: var(--color-graay-800);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  display: flex;
}

.list {
  display: flex;
  flex-wrap: wrap;
}

.buttons {
  margin-top: 4px;
}

.compliant {
  display: flex;
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 2px 8px 2px 3px;
  color: var(--color-green-700);
  background-color: var(--color-green-25);

  border-radius: 4px;
  border: 1px solid var(--color-green-700);

  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.summaryCard {
  margin: 16px;
  padding: 12px;
  border: 1px solid var(--color-gray-75);
}

.summaryCardTitle {
  color: #656a70;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.summaryCardContent {
  color: var(--color-graay-800);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.summaryCardContent ul {
  margin-left: 10px;
  padding: 12px;
}

.summaryCardContent ul li {
  margin-bottom: 10px;
}

.pill {
  line-height: 16px;
}

.sources {
  gap: 8px;
  margin-top: 4px;
  display: flex;
  flex-wrap: wrap;
}

.tooltip {
  max-width: 500px;
  margin-right: 2rem;
  text-align: justify;
}
