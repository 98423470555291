
.main {
  color: #929aa3;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.icon {
  position: relative;
  top: 3px;
  margin-right: 4px;
}

.label {
  margin-right: 4px;
}

.button {
  display: inline;
  background: none;
  color: var(--color-purple-700);
  border: none;
  cursor: pointer;
}
