.text {
  font-size: 14px;
  line-height: 16px;
  color: var(--color-black);
}

.button {
  color: white;
  font-size: 14px;
  line-height: 16px;
  padding: 5px 10px;
  border: none;
  background-color: var(--color-purple-600);
  border-radius: 2px;
  cursor: pointer;
}

.button:hover,
.button:active {
  background-color: var(--color-purple-700);
}

.button:disabled {
  opacity: .5;
}