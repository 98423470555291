.root {
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  height: 100%;
}

.header {
  display: flex;
  flex-direction: column;
  padding: 12px;
  gap: 8px;
  border-bottom: 1px solid var(--color-gray-75);
  background: #fff;
}

.headline {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.title {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.count {
  color: #929aa3;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.input {
  border-radius: 6px;
  border: 1px solid var(--color-gray-75);
  background: #fff;
  outline: none;
  padding: 0 12px;
  height: 32px;
}

.input:focus {
  border-color: var(--color-purple-600);
}

.input::placeholder {
  color: #929aa3;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.body {
  flex: 1;
  min-height: 0;
  overflow-y: auto;
}

.footer,
.footerDates {
  display: flex;
  flex-direction: row;
  margin-top: auto;
  padding: 12px;
  gap: 12px;
  border-top: 1px solid var(--color-gray-75);
}

.footer button {
  display: flex;
  flex: 1;
}

.datePickerHeader {
  padding: 12px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  border-bottom: 1px solid var(--color-gray-75);
}

.timeline {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.date {
  color: #929aa3;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.dateActions {
  display: flex;
  gap: 12px;
}

.dateActions button {
  width: 130px;
}
