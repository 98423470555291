.main {
  border-radius: 6px;
  border: 1px solid var(--color-gray-75);
  background: #f9fafb;
  display: flex;
  padding: 2px 6px;
  align-items: center;
  color:  var(--color-gray-600);;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  gap: 4px;
}
