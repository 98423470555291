.main {
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.main h2 {
  font-weight: bold;
}

.main h3 {
  font-size: 16px;
}