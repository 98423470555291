.main {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: var(--color-bg);
  gap: 20px;
  padding: 20px;
}

.back {
  display: flex;
  height: fit-content;
  align-items: center;
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  white-space: nowrap;
  position: sticky;
  top: 20px;
}

.back svg {
  width: 24px;
  height: 24px;
}

.content {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 16px;
  margin-inline: auto;
  animation: fadein 1s;
}

.title {
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  flex-direction: column-reverse;
  align-items: baseline;
  gap: 4px 8px;
  margin-bottom: 8px;
  text-wrap: nowrap;
}

.title span {
  font-size: 16px;
  font-weight: 500;
  color: var(--color-gray-muted);
}

.summary {
  margin-block: 32px;
  max-width: 60ch;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.divider {
  margin-top: 32px;
  margin-bottom: 20px;
  width: 100%;
  height: 1px;
  background: rgba(0, 0, 0, 0.2);
}

.setup {
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-top: 20px;
  list-style: none;
}

.setup li {
  margin-bottom: 8px;
}

.setup b {
  font-weight: 700;
}

.setup li span:empty::after {
  content: '–'
}

@keyframes fadein {
  0% {
    opacity: 0;
    transform: translateY(+4%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (min-width: 40rem) {
  .main {
    flex-direction: row;
  }
  .title {
    flex-direction: row;
  }
  .content {
    padding: 44px;
    max-width: 1200px;
  }
}
