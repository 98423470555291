.main {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  cursor: pointer;
  overflow: hidden;
  background: #F4EBFF;
  color: var(--color-purple-600);
  font-size: 16px;
  font-weight: 600;
  border: none;
  outline: none;
  border: 0.6px solid #e3cdfb;
}

.menu {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  border-radius: 8px;
  margin-left: 2rem;
  margin-bottom: 1rem;
  color: var(--color-white);
  min-width: 200px;
  box-shadow: var(--shadow-lg);
  border: 1px solid var(--color-divider);
  background-color: var(--color-app-black-swamp);
}

.header {
  padding: 4px 16px;
}

.email {
  font-weight: 400;
  color: var(--color-gray-200);
}

.menu li {
  list-style: none;
  padding: 9px 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
}

.menu a {
  display: flex;
  gap: 8px;
  flex: 1;
  color: var(--color-white);
}

.menu li svg {
  width: 20px;
  height: 20px;
}

.menu li:hover {
  background-color: var(--color-topbar-hover);
}

.divider {
  width: 100%;
  height: 1px;
  margin: 0.5rem 0;
  background-color: var(--color-divider);
}

.content {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  margin: 0 12px;
}

.content h3 {
  color: var(--color-gray-600);

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
