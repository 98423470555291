.main {
  display: flex;
  flex-direction: column;
  overflow: scroll;

  container-name: myComponent;
  container-type: inline-size;
}

.header {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: white;
}

.divider {
  width: 100%;
  height: 1px;
  flex-shrink: 0;
  background-color: var(--color-gray-75);
}

.content {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.icon {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-purple-50);
}

.icon svg {
  width: 20px;
  height: 20px;
}

.icon path {
  fill: var(--color-purple-600);
}

.name {
  color: var(--color-graay-800);

  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}

.item {
  display: flex;
  flex-direction: column;
}

.itemTitle {
  color: #656a70;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.itemContent {
  color: var(--color-graay-800);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  display: flex;
}

.list {
  display: flex;
  flex-wrap: wrap;
}

.entities {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
}

.pill {
  line-height: 16px;
}

.divider {
  width: 100%;
  height: 1px;
  flex-shrink: 0;
  background-color: var(--color-gray-75);
}


.summaryCard {
  margin: 16px;
  padding: 12px;
  border: 1px solid var(--color-gray-75);
}

.summaryCardTitle {
  color: #656a70;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.summaryCardContent {
  color: var(--color-graay-800);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;;
}

.summaryCardContent ul {
  margin-left: 10px;
  padding: 12px;
}

.summaryCardContent ul li {
  margin-bottom: 10px;
}

.quoteCard {
  margin: 16px;
  margin-top: 0;
}
