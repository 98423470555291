.section {
  padding: 12px 16px;
  background: var(--color-white);
  border: 1px solid #E4E4E4;
  border-radius: 4px;
  margin-top:12px;
}

.controls {
  display: flex;
  flex-direction: row;
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;

  color: #757575;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.titleDetail {
  margin: 0 10px 0 0;
  line-height: 20px;
}

.tip {
  width: 16px;
  height: 16px;
}

.tooltipWrapper {
  display: inherit;
}

.tooltip {
  text-align: left;
}

.value {
  flex-basis: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.indicator {
  width: 49px;
  height: 26px;
  border-radius: 28px;
  background-color: #f3f4f6;
  margin: 0 1.5px 4px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  color: #929aa3;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.label {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: var(--color-gray-25);
  text-align: center;
  font-size: 9px;
  font-weight: bold;
  line-height: 16px;
  color: white;
}

.value[data-selected='selected'] .indicator {
  background-color: var(--color-purple-600);
  color: white;
}

.value[data-selected='selected'] .label {
  background-color: var(--color-purple-900);
}

.value:hover .indicator {
  background-color: var(--color-gray-50);
}

.value:hover .label {
  background-color: var(--color-gray-400);
}
