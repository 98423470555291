.main {
  padding: 8px;
  font-size: 14px;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10.1px);
  -webkit-backdrop-filter: blur(10.1px);
  border: 1px solid rgba(255, 255, 255, 0.161);
}
