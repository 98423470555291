.main {
  width: 100%;
  padding: 8px 12px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  cursor: pointer;
}

.main input {
  cursor: pointer;
}

.main:hover {
  background-color: #f3f4f6;
}

.optionName {
  color: #13191f;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 166.667% */
}
