.container {
  margin: 16px;
}

.empty {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: var(--color-gray-400);
  margin: 20px 0;
  padding: 0 20px
}

.briefs {
  display: flex;
  flex-wrap: wrap;
}

.role {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: black;
  margin: 5px 0;
}

.loadingArea {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
}

.loading {
  width: 50px;
}