.button {
  outline: none;
  border: none;
  background-color: transparent;

  color: #929aa3;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  display: flex;
  gap: 4px;
  align-items: center;
  cursor: pointer;
	margin-top: 8px;
}

.button:hover {
  text-decoration: underline;
}
