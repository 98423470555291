.result {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  cursor: pointer;
}

.resultInfo {
  flex-grow: 1;
}

.resultInfo .resultPill {
  cursor: pointer;
}

.resultTitleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
}

.entityDescription {
  font-size: 12px;
  line-height: 14px;
  color: var(--color-gray-900);
  margin: 0.75rem 0 0 0;
  padding: 0 3px;
  text-transform: capitalize;
}
