html {
  box-sizing: border-box;
}

html,
body {
  font-size: 8px;
  background-color: var(--color-bg);
}

strong { font-weight: bold; }

:root {

  /* moved from xyz.assets.style */
  --color-main-dark: #D63666;
  --color-secondary: #A1A1A1;
  --color-error: #FF0000;
  --color-app-black-swamp: #131616;
  --color-app-gray-ede: #EDEDED;
  /* end */

  --color-bg: #f9fafb;

  --color-actors-hex: #8f59ff;
  --color-actors-rgb: 143, 89, 255;

  --color-plots-hex: #f23c73;
  --color-plots-rgb: 242, 60, 115;

  --color-contexts-hex: #52ceff;
  --color-contexts-rgb: 82, 206, 255;

  --color-topics-hex: #a2a2a2;
  --color-topics-rgb: 162, 162, 162;

  --color-briefs-light-hex: #efefef;
  --color-briefs-rgb: 228, 228, 228;

  --color-purple-50: #f9f5ff;
  --color-purple-100: #e8defa;
  --color-purple-200: #d6c5f7;
  --color-purple-300: #d6bbfb;
  --color-purple-600: #8553e6;
  --color-purple-700: #743ae2;
  --color-purple-800: #53389e;
  --color-purple-900: #2E1C5E;
  /* new override */
  --color-purple-200: #e9d7fe;


  --color-gray-10: #faf9f9;
  --color-gray-20: #f3f3f3;
  --color-gray-25: #e4e4e4;
  --color-gray-50: #cdcdcd;
  --color-gray-75: #e5e7eb;
  --color-gray-100: #afafaf;
  --color-gray-200: #939393;
  --color-gray-300: #D2D6DB;
  --color-gray-400: #717171;
  --color-gray-600: #515151;
  --color-gray-700: #2a2e31;
  --color-gray-800: #323232;
  --color-gray-900: #101828;
  --color-gray-950: #0e0f10;
  --color-gray-muted: #929AA3;

  --color-cyan-100: #ccf1ff;
  --color-cyan-50: #ECFEFF;
  --color-cyan-800: #155E75;

  --color-error-500: #f04438;

  --color-success-400: #32d583;

  --color-white: #ffffff;
  --color-black: #000000;

  --color-connected-background: #f6fef9;
  --color-connected-text: #20a969;
  --color-connected: #8553e6;
  --color-divider: #242424;
  --color-top-search: #222325;
  --color-topbar-hover: #262a2b;

  --text-black: #131616;
  --text-dark: #707876;

  --bg-note-card-title: #f6f6fc;

  --bg-gray: #15131a;
  --bg-color: #292c52;
  --gray-icon: #d0d0d2;
  --gray-lightest: #f9f9f9;
  --menu-bg-dark: #121617;
  --menu-overlay: #efefef;

  /*  NEW COLORS */
  --color-gray-500: #656a70;
  --color-gray-600: #42474d;
  --color-gray-700: #2a2e31;
  --color-gray-800: #222325;


  --color-pink-70: #ffe7ee;
  --color-pink-100: #fccedc;
  --color-pink-800: #750829;
  --color-pink: #F23D74;

  --color-red-600: #d92d20;

  --color-green-25: #f6fef9;
  --color-green-700: #027a48;

  --color-yellow-200: #FEDF89;
  --color-yellow-800: #93370D;


  /* tailwind v4 values https://tailwindcss.com/docs/theme */
  --breakpoint-sm: 40rem;
  --breakpoint-md: 48rem;
  --breakpoint-lg: 64rem;
  --breakpoint-xl: 80rem;
  --breakpoint-2xl: 96rem;

  --height-profile-tabs: 41px;
  --height-mobile-nav: 53px;
  --height-header: 56px;
  --height-filter-body: 320px;
  --height-header-filters: 64px;

  --width-dossier: 375px;
  --width-filters: 340px;
  --width-sidenav: 160px;
  --width-left-panel: 340px;

  --font-xl: 22px;
  --font-lg: 16px;
  --font-md: 14px;
  --font-sm: 12px;
  --font-xs: 10px;

  --shadow-light: 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  --shadow-search: 0px 1px 2px rgba(16, 24, 40, 0.05);

  --shadow-md: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
    0px 6px 8px -4px rgba(16, 24, 40, 0.08);
  --shadow-lg: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
    0px 12px 16px -4px rgba(16, 24, 40, 0.08);
  --shadow-xl: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
    var(--shadow-light);

  --border-gray-1: 1px solid var(--color-gray-75);

  /* GLOBAL ANIMATIONS */
  --skeleton-animation: skeleton 1s alternate infinite;
}

body {
  -webkit-text-size-adjust: none;
  word-break: break-word;
  margin: 0;
  padding: 0;
  line-height: 1.4;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  accent-color: var(--color-purple-700);
}


*,
*::before,
*::after {
  box-sizing: inherit;
  font-family: 'HelveticaNowText';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body > #root {
  min-height: 100dvh;
}

body > #root:has([data-cy='search']) {
  background-color: white;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

hr {
  border-top: 1px solid #444;
  border-left: 0 none;
  border-bottom: 0 none;
  border-right: 0 none;
  width: 100%;
  margin-block-start: 0;
  margin-block-end: 0;
}

a {
  text-decoration: none;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

*::-webkit-scrollbar {
  display: none;
}

* {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* GLOBAL KEYFRAMES */
@keyframes skeleton {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.5;
  }
}

@keyframes skeleton-v2 {
  to {
    background-position-x: -200%;
  }
}

.skeleton-v2 {
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1s skeleton-v2 linear infinite;
}

@keyframes zoomModal {
  0% {
    scale: 0.6;
  }
  100% {
    scale: 1;
  }
}

.spinning-icon {
  display: inline-block;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* moved from xyz.assets.style */
* {
  font-weight: normal;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
