.footer {
  display: flex;
  justify-content: space-between;
  padding: 10px !important;
}

.buttons {
  display: flex;
  align-items: center;
  gap: 4px;
}

.button {
  border: none;
  background-color: transparent;
  outline: none;
  padding: 4px 14px;
  cursor: pointer;
  color: var(--color-gray-600);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.button[data-compliant='true'] {
  color: var(--color-green-700);
  background-color: var(--color-green-25);
}

.button[data-compliant='true'] path {
  fill: var(--color-green-700);
}

.button[data-compliant='true']:hover {
  background-color: var(--color-green-25);
  filter: brightness(0.98);
}

.button:hover {
  background-color: var(--color-gray-10);
}
