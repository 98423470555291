.toggleButton {
  height: 24px;
  box-sizing: content-box;
  padding: 10px;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 0.5rem;
  border: var(--border-gray-1);
  box-shadow: var(--shadow-search);
  background-color: var(--color-white);
}

.toggleButton[data-status='expanded'] {
  display: none;
}

.toggleButton:hover {
  filter: brightness(0.97);
}

.toggleButton > svg {
  fill: var(--color-gray-900);
  width: 20px;
  height: 20px;
}
