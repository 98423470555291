.main {
  position: absolute;
  color: #fff;
  padding: 8px;
  border: 1px solid white;
  cursor: pointer;

  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.main:hover {
  filter: brightness(0.95);
}

.industryLabel {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  color: var(--color-white);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.countryLabel {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  color: var(--color-white);
  text-align: left;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.info {
  display: flex;
  flex: 1;
  /* opacity: 0; */
  flex-direction: column;
  animation: fadein 0.5s;
}

.stats {
  display: flex;
  font-size: 12px;
  justify-content: flex-end;
  animation: bottomToTop 0.5s;
}

.score {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  display: flex;
  white-space: nowrap;
}

.tooltipContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
